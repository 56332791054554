import * as actionTypes from "./actions/actionTypes";
import * as menuItem from "../components/Menu/Menu";

const initialState = {
  initialized: false,
  tutorial: false,
  reset: false,
  userId: null,
  error: false,
  geozones: null,
  activitiesCount: 0,
  servicesCount: 0,
  activities: null,
  services: null,
  activitiesCategories: null,
  servicesCategories: null,
  currentActivity: null,
  relatedActivity: null,
  questions: [],
  questions_initial_count: 0,
  selection: [],
  selectionOpened: false,
  menuTabSelected: null,
  menuSortSelected: menuItem.DEFAULT_SORT,
  searchParams: {
    has_search: false,
    geozones: null,
    public_types: null,
    level: null,
    categories: null,
    duration_min: null,
    duration_max: null,
    price_min: null,
    price_max: null,
    locomotion_types: null,
    date_min: null,
    date_max: null,
    time_min: null,
    time_max: null,
    user_location: null,
    user_locomotion_type: null,
    travel_time_max: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INIT_APP_DATA:
      return {
        ...state,
        initialized: true,
        tutorial: true,
        reset: false,
        error: false,
        geozones: action.geozones,
        activitiesCount: action.activitiesCount,
        servicesCount: action.servicesCount,
        activities: action.activities,
        services: action.services,
        activitiesCategories: action.activitiesCategories,
        servicesCategories: action.servicesCategories,
        currentActivity: null,
        relatedActivity: null,
        questions: [],
        selection: [],
        selectionOpened: false,
      };

    case actionTypes.RESET:
      return {
        ...state,
        initialized: false,
        tutorial: false,
        reset: true,
        userId: null,
        menuTabSelected: null,
        menuSortSelected: null,
        searchParams: {
          ...state.searchParams,
          has_search: false,
          geozones: null,
          public_types: null,
          level: null,
          categories: null,
          duration_min: null,
          duration_max: null,
          price_min: null,
          price_max: null,
          locomotion_types: null,
          date_min: null,
          date_max: null,
          time_min: null,
          time_max: null,
          user_location: null,
          user_locomotion_type: null,
          travel_time_max: null,
        },
      };

    case actionTypes.SWITCH_LANGUAGE:
      return {
        ...state,
        initialized: false,
        tutorial: false,
        menuTabSelected: null,
        menuSortSelected: null,
        searchParams: {
          ...state.searchParams,
          has_search: false,
          geozones: null,
          public_types: null,
          level: null,
          categories: null,
          duration_min: null,
          duration_max: null,
          price_min: null,
          price_max: null,
          locomotion_types: null,
          date_min: null,
          date_max: null,
          time_min: null,
          time_max: null,
          user_location: null,
          user_locomotion_type: null,
          travel_time_max: null,
        },
      };

    case actionTypes.SET_USER_ID:
      return {
        ...state,
        userId: action.userId,
      };

    case actionTypes.SET_TUTORIAL:
      return {
        ...state,
        tutorial: action.active,
      };

    case actionTypes.SET_GEOZONES:
      return {
        ...state,
        geozones: action.geozones,
      };

    case actionTypes.UPDATE_GEOZONES:
      return {
        ...state,
        geozones: state.geozones.map((geozone) => {
          const geozoneUpdated = action.geozones.find((g) => {
            return g.id === geozone.id;
          });
          return {
            ...geozone,
            forecasts: geozoneUpdated.forecasts,
            activities: geozoneUpdated.activities,
          };
        }),
      };

    case actionTypes.FETCH_GEOZONES_FAILED:
      return {
        ...state,
        error: true,
      };

    case actionTypes.SET_ACTIVITIES:
      return {
        ...state,
        error: false,
        menuSortSelected: menuItem.DEFAULT_SORT,
        activitiesCount: action.activitiesCount,
        servicesCount: action.servicesCount,
        activities: action.activities,
        services: action.services,
      };

    case actionTypes.FETCH_ACTIVITIES_FAILED:
      return {
        ...state,
        error: true,
      };

    case actionTypes.SET_CURRENT_ACTIVITY:
      return {
        ...state,
        error: false,
        currentActivity: action.currentActivity,
      };

    case actionTypes.SET_RELATED_ACTIVITY:
      return {
        ...state,
        error: false,
        relatedActivity: action.relatedActivity,
      };
  

    case actionTypes.SET_QUESTIONS:
      return {
        ...state,
        error: false,
        questions: action.questions,
        questions_initial_count: action.questions_initial_count,
      };

    case actionTypes.FETCH_QUESTIONS_FAILED:
      return {
        ...state,
        error: true,
      };

    case actionTypes.REMOVE_QUESTION:
      return {
        ...state,
        questions: state.questions.filter(
          (q) => q.question_id !== action.question.question_id
        ),
      };

    case actionTypes.ADD_ACTIVITY_TO_SELECTION:
      return {
        ...state,
        selection: state.selection.concat(action.activity),
      };

    case actionTypes.REMOVE_ACTIVITY_FROM_SELECTION:
      return {
        ...state,
        selection: state.selection.filter((a) => a.id !== action.activity.id),
      };

    case actionTypes.TOGGLE_SELECTION:
      return {
        ...state,
        selectionOpened: action.selectionOpened,
      };

    case actionTypes.UPDATE_SEARCH:
      let hasSearch =
        action.params.geozones !== null ||
        action.params.public_types !== null ||
        action.params.level !== null ||
        action.params.categories !== null ||
        action.params.duration_min !== null ||
        action.params.duration_max !== null ||
        action.params.price_min !== null ||
        action.params.price_max !== null ||
        action.params.time_min !== null ||
        action.params.time_max !== null ||
        action.params.locomotion_types !== null ||
        action.params.date_min !== null ||
        action.params.date_max !== null ||
        action.params.user_location !== null ||
        action.params.user_locomotion_type !== null ||
        (action.params.travel_time_max !== null && action.params.travel_time_max !== 0);
      return {
        ...state,
        searchParams: {
          ...action.params,
          has_search: hasSearch,
        },
      };

    case actionTypes.RESET_SEARCH:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          has_search: false,
          geozones: null,
          public_types: null,
          level: null,
          categories: null,
          duration_min: null,
          duration_max: null,
          price_min: null,
          price_max: null,
          locomotion_types: null,
          date_min: null,
          date_max: null,
          time_min: null,
          time_max: null,
          user_location: null,
          user_locomotion_type: null,
          travel_time_max: null,
        },
      };

    case actionTypes.SET_USER_LOCATION:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          user_location: action.userLocation,
        },
      };

    case actionTypes.SORT_ACTIVITIES:
      return {
        ...state,
        menuSortSelected: action.sortBy,
      };

    default:
      return state;
  }
};

export default reducer;
