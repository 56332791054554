import React from 'react'

import classes from './ActivityIcon.module.css';

const activityIcon = (props) => {
  return (
    <div className={[classes.ActivityIcon, props.type].join(" ")}>
      <img
        src={
          typeof props.icon === "string"
            ? props.icon
            : props.icon !== undefined ? props.icon.url : ""
        }
        alt={props.label}
      />
      <span>{props.label}</span>
    </div>
  );
}

export default activityIcon;
