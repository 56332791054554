import React from 'react'

import classes from './Switch.module.css';

const switchBox = (props) => {
  return (
    <label className={classes.Switch}>
      <input type='checkbox' checked={props.isOn} onChange={props.changed}></input>
      <span className={classes.Slider}></span>
    </label>
  );
}

export default switchBox;
