export const INIT_APP_DATA = 'INIT_APP_DATA';
export const RESET = 'RESET';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_TUTORIAL = 'SET_TUTORIAL';
export const SET_GEOZONES = 'SET_GEOZONES';
export const UPDATE_GEOZONES = 'UPDATE_GEOZONES';
export const FETCH_GEOZONES_FAILED = 'FETCH_GEOZONES_FAILED';
export const SET_ACTIVITIES = 'SET_ACTIVITIES';
export const FETCH_ACTIVITIES_FAILED = 'FETCH_ACTIVITIES_FAILED';
export const SET_CURRENT_ACTIVITY = 'SET_CURRENT_ACTIVITY';
export const SET_RELATED_ACTIVITY = 'SET_RELATED_ACTIVITY';
export const ADD_ACTIVITY_TO_SELECTION = 'ADD_ACTIVITY_TO_SELECTION';
export const REMOVE_ACTIVITY_FROM_SELECTION = 'REMOVE_ACTIVITY_FROM_SELECTION';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const FETCH_QUESTIONS_FAILED = 'FETCH_QUESTIONS_FAILED';
export const REMOVE_QUESTION = 'REMOVE_QUESTION';
export const TOGGLE_SELECTION = 'TOGGLE_SELECTION';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const SORT_ACTIVITIES = 'SORT_ACTIVITIES';