import React from 'react';
import { useTranslation } from 'react-i18next';

import classes from './Screensaver.module.css';
import logo from '../../../assets/images/screensaver_logo.svg';

const Screensaver = (props) => {

  const { t } = useTranslation();

  return (
    <div className={classes.Screensaver}>
      <div className={classes.Content}>
        <img className={classes.Logo} src={logo} alt='Maurienne Galibier' width='400px' />
        <span>{t('screensaver.text')}</span>
      </div>
    </div>
  );
}

export default Screensaver;
