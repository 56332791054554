import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/actions';

import Auxiliary from '../hoc/Auxiliary/Auxiliary';
import Layout from '../hoc/Layout/Layout';
import Loader from '../components/UI/Loader/Loader';
import Screensaver from '../components/UI/Screensaver/Screensaver';
import Map from './Map/Map';
import SidePanel from './SidePanel/SidePanel';
// import { GAInit, GASessionStart, GASessionEnd } from '../components/Analytics/Analytics';
import { GAInit, GASessionStart, GASessionEnd } from '../components/Analytics/Firebase';

const INACTIVE_USER_TIME_THRESHOLD = 2 * 60 * 1000; // 2 min

class App extends Component {

  userInactivityTimeout = null;

  state = {
    isInactive: true,
  }

  componentDidMount() {
    
    GAInit();
    this.props.initApp();
  }

  componentWillUnmount() {
    
    this.deactivateActivityTracker();
  }

  componentDidUpdate(prevProps, prevState) {

    if (!prevProps.initialized && this.props.initialized) {
      this.onAppDidInitialize();
    } else if (prevState.isInactive && !this.state.isInactive) {
      this.onAppDidBecomeActive();
    } else if (!prevProps.reset && this.props.reset) {
      this.onAppDidTerminate();
    }
  }

  activateActivityTracker = () => {
    
    window.addEventListener("mousemove", this.userActivityTracker);
    window.addEventListener("scroll", this.userActivityTracker);
    window.addEventListener("mousedown", this.userActivityTracker);
  }

  deactivateActivityTracker = () => {
    
    window.removeEventListener("mousemove", this.userActivityTracker);
    window.removeEventListener("scroll", this.userActivityTracker);
    window.removeEventListener("mousedown", this.userActivityTracker);
    clearTimeout(this.userInactivityTimeout);
  }

  resetUserActivityTimeout = () => {
    
    clearTimeout(this.userInactivityTimeout);
    this.userInactivityTimeout = setTimeout(() => {
      if (!this.state.isInactive) {
        this.onAppDidBecomeInactive();
      }
    }, INACTIVE_USER_TIME_THRESHOLD);
  }

  userActivityTracker = () => {

    if (this.state.isInactive) {
      this.setState({
        isInactive: false,
      });
    }
    
    this.resetUserActivityTimeout();
  }

  onAppDidInitialize = () => {
    
    console.log('App initialized');
    this.activateActivityTracker();
  }

  onAppDidBecomeActive = () => {

    console.log('App did become active');
    const userId = GASessionStart();
    this.props.setUserId(userId);
  }

  onAppDidBecomeInactive = () => {

    console.log('App did become inactive');
    this.setState({
      isInactive: true,
    }, () => {
      this.props.resetApp();
    });
  }

  onAppDidTerminate = () => {
    
    console.log('App did terminate');
    this.setState({
      isInactive: true,
    }, () => {
      this.resetUserActivityTimeout();
    });
    GASessionEnd();
  }

  render() {

    const screensaver = this.state.isInactive ? <Screensaver /> : null;

    return (
      <Auxiliary>
      {this.props.initialized ?
        <Layout>
          <Map />
          <SidePanel />
          {screensaver}
        </Layout> 
        : <Loader />
      }
      </Auxiliary>
    );
  }
}

const mapStateToProps = state => {
  return {
    initialized: state.initialized,
    tutorial: state.tutorial,
    reset: state.reset,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    initApp: () => dispatch(actions.initApp()),
    setUserId: (userId) => dispatch(actions.setUserId(userId)),
    resetApp: () => dispatch(actions.resetApp()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);