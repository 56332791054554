import React, { Component } from "react";
import { connect } from "react-redux";
import { /*Trans,*/ withTranslation } from "react-i18next";
import Transition from "react-transition-group/Transition";
import * as actions from "../../store/actions";
import { GAEvent, GAUserProperties } from "../../components/Analytics/Firebase";

import classes from "./Menu.module.css";
import Profil from "./Profil/Profil";
import Wishes from "./Wishes/Wishes";
import Calendar from "./Calendar/Calendar";
import { SERVICES_TAB } from "../../containers/Home/Home";

export const PROFIL_TAB = "profil";
export const WISHES_TAB = "wishes";
export const CALENDAR_TAB = "calendar";

export const DEFAULT_SORT = "score";
export const CATEGORY_SORT = "category";
export const DURATION_SORT = "duration";
export const DISTANCE_SORT = "distance";
export const PRICE_SORT = "price";

class Menu extends Component {

  state = {
    tabSelected: this.props.defaultTabSelected,
    sortSelected: this.props.defaultSortSelected,
    searchParams: this.props.searchParams,
    showSortMenu: false,
    categories: [],
  };

  componentDidMount() {
    this.initCategories();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.initialized && !this.props.initialized) {
      this.setState({
        tabSelected: this.props.defaultTabSelected,
        sortSelected: this.props.defaultSortSelected,
        searchParams: this.props.searchParams,
        showSortMenu: false,
      });
    } else if (prevProps.searchParams.has_search !== this.props.searchParams.has_search) {
      this.setState({
        searchParams: this.props.searchParams
      });
    } else if (prevProps.defaultSortSelected !== this.props.defaultSortSelected) {
      this.setState({
        sortSelected: this.props.defaultSortSelected,
      });
    } else if (prevProps.searchParams.geozones !== this.props.searchParams.geozones) {
      this.setState({
        searchParams: {
          ...this.state.searchParams,
          geozones: this.props.searchParams.geozones,
        },
      });
    } else if (prevProps.searchParams.user_location !== this.props.searchParams.user_location) {
      this.setState({
        searchParams: {
          ...this.state.searchParams,
          user_location: this.props.searchParams.user_location,
        },
      });
    } else if (prevProps.type !== this.props.type) {
      this.initCategories();
    }
  }

  initCategories = () => {

    this.setState({
      tabSelected: this.props.defaultTabSelected,
      sortSelected: this.props.defaultSortSelected,
      showSortMenu: false,
      categories: this.props.type === SERVICES_TAB ? this.props.servicesCategories : this.props.activitiesCategories,
    });
  };

  tabClickHandler = (tab) => {
    if (tab !== this.state.tabSelected) {
      // GAEvent('menu', 'open_' + tab + '_tab', 'Open ' + tab + ' tab');
      GAEvent("open_" + tab + "_tab");
      this.setState({
        tabSelected: tab,
      });
    } else {
      // GAEvent('menu', 'close_' + tab + '_tab', 'Close ' + tab + ' tab');
      GAEvent("close_" + tab + "_tab");
      this.setState({
        tabSelected: null,
      });
    }
  };

  closeTabHandler = () => {
    // GAEvent('menu', 'close_' + this.state.tabSelected + '_tab', 'Close ' + this.state.tabSelected + ' tab');
    GAEvent("close_" + this.state.tabSelected + "_tab");
    this.setState({
      tabSelected: null,
    });
  };

  toggleSortMenu = () => {
    this.setState({
      showSortMenu: !this.state.showSortMenu,
    });
  };

  closeSortMenu = () => {
    this.setState({
      showSortMenu: false,
    });
  };

  sortHandler = (sortBy = null) => {
    if (sortBy) {
      // GAEvent('menu', 'sort_by_' + sortBy, 'Sort by ' + sortBy);
      GAEvent("sort_by_" + sortBy);
    }

    this.setState(
      {
        sortSelected: sortBy,
        showSortMenu: false,
      },
      () => {
        this.props.sortActivities(this.state.sortSelected);
      }
    );
  };

  nextTabHandler = (nextTab) => {
    // GAEvent('menu', 'open_' + nextTab + '_tab_via_' + this.state.tabSelected, 'Open ' + nextTab + ' tab via ' + this.state.tabSelected);
    GAEvent("open_" + nextTab + "_tab_via_" + this.state.tabSelected);
    this.setState({
      tabSelected: nextTab,
    });
  };

  onProfilValidate = (data, goToNext = false) => {
    const searchParams = {
      ...this.state.searchParams,
    };

    searchParams.public_types = data.publicType;
    searchParams.user_location = this.props.searchParams.user_location;
    searchParams.user_locomotion_type = data.userLocomotionType;
    searchParams.travel_time_max = data.travelTimeMax;

    if (this.state.searchParams !== searchParams) {
      this.setState(
        {
          searchParams: searchParams,
        },
        () => {
          if (goToNext) {
            this.nextTabHandler(CALENDAR_TAB);
          } else {
            this.doSearch(searchParams);
          }
        }
      );
    } else {
      if (goToNext) {
        this.nextTabHandler(CALENDAR_TAB);
      } else {
        this.closeTabHandler();
      }
    }
  };

  onUserLocationDelete = () => {
    // GAEvent('map', 'delete_user_location', 'Delete user location');
    GAEvent("delete_user_location");

    const searchParams = {
      ...this.state.searchParams,
    };

    searchParams.userLocation = null;

    this.setState(
      {
        searchParams: searchParams,
      },
      () => {
        this.props.deleteUserLocation();
      }
    );
  };

  priceSelected = (min, max) => {
    if (max === 0) {
      return "1";
    }
    if (max === 20) {
      return "2";
    }
    if (max === 50) {
      return "3";
    }
    if (min === 51) {
      return "4";
    }
    return null;
  };

  durationSelected = (min, max) => {
    if (min === 0 && max === 119) {
      return "1";
    }
    if (min === 120 && max === 239) {
      return "2";
    }
    if (min === 240 && max === 359) {
      return "3";
    }
    if (min === 360) {
      return "4";
    }
    return null;
  };

  onWishesValidate = (data, goToNext = false) => {
    const searchParams = {
      ...this.state.searchParams,
    };
    searchParams.categories = null;
    if (data.subcategory) {
      searchParams.categories = data.subcategory;
    } else if (data.category) {
      searchParams.categories = data.category;
    }
    searchParams.duration_min = data.durationMin;
    searchParams.duration_max = data.durationMax;
    searchParams.price_min = data.priceMin;
    searchParams.price_max = data.priceMax;
    searchParams.level = data.level;

    if (this.state.searchParams !== searchParams) {
      this.setState(
        {
          searchParams: searchParams,
        },
        () => {
          if (goToNext) {
            this.nextTabHandler(PROFIL_TAB);
          } else {
            this.doSearch(searchParams);
          }
        }
      );
    } else {
      if (goToNext) {
        this.nextTabHandler(PROFIL_TAB);
      } else {
        this.closeTabHandler();
      }
    }
  };

  onCalendarValidate = (data) => {
    const searchParams = {
      ...this.state.searchParams,
    };

    let date_min = null;
    let date_max = null;
    let time_min = null;
    let time_max = null;

    if (Array.isArray(data.dateRange)) {
      if (data.dateRange[0]) {
        date_min =
          data.dateRange[0].getTime() / 1000 -
          data.dateRange[0].getTimezoneOffset() * 60;
      }
      if (data.dateRange[1]) {
        date_max =
          data.dateRange[1].getTime() / 1000 -
          data.dateRange[1].getTimezoneOffset() * 60;
      }
    }
    if (Array.isArray(data.dateRange)) {
      if (data.dateRange[0]) {
        time_min =
          data.dateRange[0].getHours() +
          ":" +
          data.dateRange[0].getMinutes() +
          ":" +
          data.dateRange[0].getSeconds();
      }
      if (data.dateRange[1]) {
        time_max = data.dateRange[1];
      } else {
        // On rajoute 4h par rapport au 'time_min'
        time_max =
          data.dateRange[0].getHours() +
          4 +
          ":" +
          data.dateRange[0].getMinutes() +
          ":" +
          data.dateRange[0].getSeconds();
      }
    }

    searchParams.date_min = date_min;
    searchParams.date_max = date_max;
    searchParams.time_min = time_min;
    searchParams.time_max = time_max;

    if (this.state.searchParams !== searchParams) {
      this.setState(
        {
          searchParams: searchParams,
        },
        () => {
          this.doSearch(searchParams);
        }
      );
    } else {
      this.closeTabHandler();
    }
  };

  doSearch = (searchParams) => {

    const params = {
      ...searchParams
    };

    if (this.props.type === SERVICES_TAB) {
      params.public_types = null;
    }

    if (params.categories) {

      if (params.categories.filters) {

        if (!params.categories.filters.includes('level')) {
          params.level = null;
        }

        if (!params.categories.filters.includes('duration')) {
          params.duration_min = null;
          params.duration_max = null;
        }

        if (!params.categories.filters.includes('cost')) {
          params.price_min = null;
          params.price_max = null;
        }

      } else {
        
        params.duration_min = null;
        params.duration_max = null;
        params.level = null;
        params.price_min = null;
        params.price_max = null;
      }
    }

    GAUserProperties(params);
    this.props.updateSearch(params);

    document.getElementById("list-container").scrollTop = 0;
    this.closeTabHandler();
  };

  render() {

    const { t } = this.props;

    const transitionStyles = {
      entering: {
        opacity: 0,
      },
      entered: {
        opacity: 1,
      },
      exiting: {
        opacity: 0,
      },
      exited: {
        opacity: 0,
      },
    };

    let breadcrumbClasses = [classes.Breadcrumb];
    if (this.state.tabSelected) {
      breadcrumbClasses.push(classes.Active);
    }

    let categorySelected = null;
    let subcategorySelected = null;
    if (this.state.searchParams.categories) {
      if (this.state.searchParams.categories.parent) {
        categorySelected = this.state.searchParams.categories.parent;
        subcategorySelected = this.state.searchParams.categories;
      } else {
        categorySelected = this.state.searchParams.categories;
      }
    }

    return (
      <div className={[classes.Menu, this.props.transitionState].join(" ")}>
        <div className={breadcrumbClasses.join(" ")}>
          <ul>
            <li
              className={
                this.state.tabSelected === WISHES_TAB ? classes.Active : null
              }
            >
              <button
                className={classes.MyWishes}
                onClick={() => this.tabClickHandler(WISHES_TAB)}
              >
                {t("menu.wishes")}
              </button>
            </li>
            <li
              className={
                this.state.tabSelected === PROFIL_TAB ? classes.Active : null
              }
            >
              <button
                className={classes.MyProfile}
                onClick={() => this.tabClickHandler(PROFIL_TAB)}
              >
                <span>
                  {t("menu.profil.1")} <br />
                  {/*<span>
                    <Trans i18nKey="menu.profil.2" />
                  </span>*/}
                </span>
              </button>
            </li>
            <li
              className={
                this.state.tabSelected === CALENDAR_TAB ? classes.Active : null
              }
            >
              <button
                className={classes.MyDates}
                onClick={() => this.tabClickHandler(CALENDAR_TAB)}
              >
                {t("menu.calendar")}
              </button>
            </li>
            <li
              className={this.state.showSortMenu ? classes.Active : null}
              tabIndex="0"
              onBlur={this.closeSortMenu}
            >
              <button className={classes.SortBy} onClick={this.toggleSortMenu}>
                {t("menu.sort.title")}
              </button>
              <div
                className={classes.SortByMenu}
                style={{ display: this.state.showSortMenu ? "block" : "none" }}
              >
                <ul>
                  <li
                    className={
                      this.state.sortSelected === DEFAULT_SORT
                        ? classes.Active
                        : null
                    }
                  >
                    <button onMouseDown={() => this.sortHandler(DEFAULT_SORT)}>
                      {t("menu.sort.pertinence")}
                    </button>
                  </li>
                  <li
                    className={
                      this.state.sortSelected === CATEGORY_SORT
                        ? classes.Active
                        : null
                    }
                  >
                    <button onMouseDown={() => this.sortHandler(CATEGORY_SORT)}>
                      {t("menu.sort.category")}
                    </button>
                  </li>
                  <li
                    className={
                      this.state.sortSelected === DURATION_SORT
                        ? classes.Active
                        : null
                    }
                  >
                    <button onMouseDown={() => this.sortHandler(DURATION_SORT)}>
                      {t("menu.sort.duration")}
                    </button>
                  </li>
                  <li
                    className={
                      this.state.sortSelected === PRICE_SORT
                        ? classes.Active
                        : null
                    }
                  >
                    <button onMouseDown={() => this.sortHandler(PRICE_SORT)}>
                      {t("menu.sort.price")}
                    </button>
                  </li>
                  {this.props.searchParams.user_location ? (
                    <li
                      className={
                        this.state.sortSelected === DISTANCE_SORT
                          ? classes.Active
                          : null
                      }
                    >
                      <button
                        onMouseDown={() => this.sortHandler(DISTANCE_SORT)}
                      >
                        {t("menu.sort.distance")}
                      </button>
                    </li>
                  ) : null}
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div className={classes.Container}>
          <Transition
            in={this.state.tabSelected === PROFIL_TAB}
            timeout={200}
            mountOnEnter
            unmountOnExit
          >
            {(state) => (
              <div style={{ ...transitionStyles[state] }}>
                <Profil
                  type={this.props.type}
                  publicTypeSelected={this.state.searchParams.public_types}
                  userLocation={this.props.searchParams.user_location}
                  userLocomotionTypeSelected={
                    this.state.searchParams.user_locomotion_type
                  }
                  travelTimeMaxSelected={
                    this.state.searchParams.travel_time_max
                  }
                  levelSelected={this.state.searchParams.level}
                  deleteUserLocation={this.onUserLocationDelete}
                  submit={this.onProfilValidate}
                />
              </div>
            )}
          </Transition>
          <Transition
            in={this.state.tabSelected === WISHES_TAB}
            timeout={200}
            mountOnEnter
            unmountOnExit
          >
            {(state) => (
              <div style={{ ...transitionStyles[state] }}>
                <Wishes
                  type={this.props.type}
                  categories={this.state.categories}
                  categorySelected={categorySelected}
                  subcategorySelected={subcategorySelected}
                  levelSelected={this.state.searchParams.level}
                  durationSelected={this.durationSelected(
                    this.state.searchParams.duration_min,
                    this.state.searchParams.duration_max
                  )}
                  priceSelected={this.priceSelected(
                    this.state.searchParams.price_min,
                    this.state.searchParams.price_max
                  )}
                  locomotionTypeSelected={
                    this.state.searchParams.locomotion_types
                  }
                  submit={this.onWishesValidate}
                />
              </div>
            )}
          </Transition>
          <Transition
            in={this.state.tabSelected === CALENDAR_TAB}
            timeout={200}
            mountOnEnter
            unmountOnExit
          >
            {(state) => (
              <div style={{ ...transitionStyles[state] }}>
                <Calendar
                  dateMinSelected={this.state.searchParams.date_min}
                  dateMaxSelected={this.state.searchParams.date_max}
                  timeMinSelected={this.state.searchParams.time_min}
                  timeMaxSelected={this.state.searchParams.time_max}
                  submit={this.onCalendarValidate}
                />
              </div>
            )}
          </Transition>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialized: state.initialized,
    defaultTabSelected: state.menuTabSelected,
    defaultSortSelected: state.menuSortSelected,
    activities: state.activities,
    services: state.services,
    activitiesCategories: state.activitiesCategories,
    servicesCategories: state.servicesCategories,
    searchParams: state.searchParams
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSearch: (params) => dispatch(actions.updateSearch(params)),
    sortActivities: (sortBy) => dispatch(actions.sortActivities(sortBy)),
    deleteUserLocation: () => dispatch(actions.setUserLocation(null))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Menu));
