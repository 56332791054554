import * as menuItem from '../components/Menu/Menu';

export const sortOn = (key) => {
  return (a,b) => {
    if (a[key] > b[key]) return 1;
    if (a[key] < b[key]) return -1;
    return 0;
  }
};

export const sortActivitiesBy = (key) => {
  if (!key) return;
  switch (key) {
    case menuItem.DEFAULT_SORT:
      return (a, b) => {
        if (a.score > b.score) return -1;
        if (a.score < b.score) return 1;
        if (a.score === b.score) {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
        }
        return 0
      }
    case menuItem.CATEGORY_SORT:
      return (a, b) => {
        if (a.category.label > b.category.label) return 1;
        if (a.category.label < b.category.label) return -1;
        if (a.category.label === b.category.label) {
          if (a.score > b.score) return -1;
          if (a.score < b.score) return 1;
          if (a.score === b.score) {
            if (a.category.detailed_label > b.category.detailed_label) return 1;
            if (a.category.detailed_label < b.category.detailed_label) return -1;
          }
        }
        return 0;
      }
    case menuItem.PRICE_SORT:
      return (a, b) => {
        if (a.price_to_compare > b.price_to_compare) return 1;
        if (a.price_to_compare < b.price_to_compare) return -1;
        if (a.price_to_compare === b.price_to_compare) {
          if (a.score > b.score) return -1;
          if (a.score < b.score) return 1;
        }
        return 0
      }
    case menuItem.DURATION_SORT:
      return (a, b) => {
        if (a.duration_to_compare > b.duration_to_compare) return 1;
        if (a.duration_to_compare < b.duration_to_compare) return -1;
        if (a.duration_to_compare === b.duration_to_compare) {
          if (a.score > b.score) return -1;
          if (a.score < b.score) return 1;
        }
        return 0
      }
    default:
      return (a, b) => {
        if (a[key] > b[key]) return 1;
        if (a[key] < b[key]) return -1;
        return 0;
      }
  }
};