import React, { useState, useEffect } from "react";
import ReactCalendar from "react-calendar";
import { useTranslation } from "react-i18next";

import classes from "../MenuItem.module.css";

const Calendar = (props) => {
  const { t } = useTranslation();

  const [calendarKey, setCalendarKey] = useState("calendar");
  const [initialDate, setInitialDate] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [dateSelected, setDateSelected] = useState(false);

  useEffect(() => {
    let dateMin = null;
    if (props.dateMinSelected) {
      dateMin = new Date(props.dateMinSelected * 1000);
      dateMin = new Date(
        dateMin.getTime() + dateMin.getTimezoneOffset() * 60 * 1000
      );
    }
    let dateMax = null;
    if (props.dateMaxSelected) {
      dateMax = new Date(props.dateMaxSelected * 1000);
      dateMax = new Date(
        dateMax.getTime() + dateMax.getTimezoneOffset() * 60 * 1000
      );
    }
    setDateRange([dateMin, dateMax]);
    setInitialDate(true); // only once when the component is mounted
  }, []);

  useEffect(() => {
    if (
      dateRange === null ||
      (Array.isArray(dateRange) &&
        dateRange[0] === null &&
        dateRange[1] === null)
    ) {
      setDateSelected(false);
      setCalendarKey(
        (calendarKey) =>
          calendarKey + Math.floor(Math.random() * Math.floor(10))
      ); // random key to force calendar remount
    } else {
      setDateSelected(true);
    }
  }, [dateRange]);

  const dateChangeHandler = (value, event) => {
    let dates = null;
    if (!Array.isArray(value)) {
      if (initialDate) {
        dates = [value, null];
      } else if (dateRange[0] && !dateRange[1]) {
        dates = [dateRange[0], value];
      } else {
        dates = [value, null];
      }
    } else {
      dates = value;
    }
    setDateRange(dates);
  };

  const resetDate = () => {
    setDateRange(null);
  };

  //Date d'aujourd'hui + heure actuelle
  const now = new Date();

  //Date d'aujourd'hui
  const today = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0
  );

  //Date de demain
  const tomorrow = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1,
    0,
    0,
    0
  );

  //La semaine en cours
  const getDayIndexMondaySunday = (date) =>
    date.getDay() === 0 ? 6 : date.getDay() - 1;
  const week = [
    today,
    new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + (6 - getDayIndexMondaySunday(new Date())),
      0,
      0,
      0
    ),
  ];

  return (
    <div className={classes.MenuItem}>
      <section>
        <span>{t("calendar.title")}</span>
        <br />
        <span className={classes.Smaller}>{t("calendar.subtitle")}</span>
        <div className={classes.buttons}>
          <button
            id="btn-now"
            onClick={(event) => { 
              setDateRange(null);
              dateChangeHandler(now, event)
            }}
          >
            {t("calendar.button.now")}
          </button>
          <button
            id="btn-today"
            onClick={(event) =>  { 
              setDateRange(null);
              dateChangeHandler(today, event)
            }}
          >
            {t("calendar.button.today")}
          </button>
          <button
            id="btn-tomorrow"
            onClick={(event) =>  { 
              setDateRange(null);
              dateChangeHandler(tomorrow, event)
            }}
          >
            {t("calendar.button.tomorrow")}
          </button>
          <button
            id="btn-week"
            onClick={(event) =>  { 
              setDateRange(null);
              dateChangeHandler(week, event)
            }}
          >
            {t("calendar.button.week")}
          </button>
        </div>
        <div className={classes.Row}>
          <h2 className={classes.month}>
            {now.toLocaleString("fr-FR", { month: "long" })}
          </h2>
        </div>
        <ReactCalendar
          key={calendarKey}
          onChange={(value, event) => dateChangeHandler(value, event)}
          locale={t("calendar.locale")}
          minDetail="month"
          maxDetail="month"
          showNavigation={false}
          selectRange={true}
          allowPartialRange={true}
          minDate={new Date()}
          value={dateRange}
          showNeighboringMonth={false}
        />
      </section>
      <div className={classes.Actions}>
        {dateSelected ? 
            <button className={classes.Delete} onClick={resetDate}>{t("calendar.delete")}</button>
            : null
        }
        <button className={classes.Next} onClick={() => props.submit({ dateRange: dateRange })}>
          {t("calendar.next.1")}
          <span className={classes.Smaller}>{t("calendar.next.2")}</span>
        </button>
      </div>
    </div>
  );
};

export default Calendar;
