import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import * as actions from '../../../store/actions';

import classes from './ActivityItem.module.css';
import * as ActivityHelper from '../ActivitiesHelper';
import iconFavorites from '../../../assets/images/icon_favorites.svg';
import iconFavoritesSelected from '../../../assets/images/icon_favorites_selected.svg';
// import { GAActivityImpression, GAActivityClick, GAAddToSelection, GARemoveFromSelection } from '../../Analytics/Analytics';
import { GAActivityClick, GAAddToSelection, GARemoveFromSelection } from '../../Analytics/Firebase';

class ActivityItem extends Component {

  state = {
    descriptionMaxLength: 145
  }

  descriptionRef = React.createRef();

  componentDidMount() {
    
    if (this.descriptionRef.current) {
      var width = document.querySelector('#activity-' + this.props.activity.id + '-description').offsetWidth;
      if (width > 0) {
        this.setState({
          descriptionMaxLength: Math.ceil(width * 0.3)
        });
      }
    }
    // GAActivityImpression(this.props.activity, this.props.gaList);
  }

  itemClickHandler = () => {

    GAActivityClick(this.props.activity, this.props.gaList);
    this.props.setActivity({
      ...this.props.activity,
      flyTo: true
    });
  }

  favoritesChangeHandler = (event) => {

    if (event.target.checked) {
      GAAddToSelection(this.props.activity);
      this.props.addToSelection(this.props.activity);
    } else {
      GARemoveFromSelection(this.props.activity);
      this.props.removeFromSelection(this.props.activity);
    }
  }

  bestForecastDate = () => {

    if (this.props.activity.weather_max) {
      let bestForecast = null;
      let bestForecastScore = 100;
      this.props.geozones.forEach(g => {
        if (g.id === this.props.activity.geo_zone_id && g.forecasts) {
          g.forecasts.forEach(f => {
            if (f.forecast_score < bestForecastScore) {
              if (this.props.activity.weather_max >= f.forecast_score) {
                bestForecastScore = f.forecast_score;
                bestForecast = f;
              }
            }
          })
        }
      });
      return bestForecast;
    }
    return null;
  }

  render() {

    const { t } = this.props;

    const isSelected = this.props.selection.filter(a => a.id === this.props.activity.id).length > 0;

    let forecast = null;
    if (this.props.showWeatherForecast) {
      const bestForecast = this.bestForecastDate();
      if (bestForecast) {
        const dateFormated = new Intl.DateTimeFormat(i18n.language, { day: '2-digit', month: '2-digit' }).format(new Date(bestForecast.date));
        forecast = 
          <div className={classes.Forecast} style={{backgroundImage: 'url('+bestForecast.forecast_icon.url+')'}}>
            <div className={classes.BestDay}>
              <p>{t('activity.weather_best_day')}</p>
              <div className={classes.Date}>
                <span>{dateFormated}</span>
              </div>
            </div>
          </div>;
      }
    }

    let description = "";
    if (this.props.activity.description_short) {
      description = this.props.activity.description_short;
      if (description.length >= this.state.descriptionMaxLength) {
        description = description.substring(0, this.state.descriptionMaxLength) + '...';
      }
    }

    return (
      <div id={'activity-item-'+this.props.activity.id} className={classes.ActivityItem}>
        {forecast}
        <div className={classes.Content} onClick={this.itemClickHandler}>
          <div className={classes.Left}>
            {this.props.activity.images ? 
              <img src={this.props.activity.images[0].url} alt={this.props.activity.images[0].copyright} /> : 
              this.props.activity.image ? <img src={this.props.activity.image.url} alt={this.props.activity.image.copyright} /> 
              : null
            }
          </div>
          <div className={classes.Center}>
            <p className={classes.Title}>{this.props.activity.name}</p>
            <p id={"activity-" + this.props.activity.id + "-description"} className={classes.Description} ref={this.descriptionRef}><span>{description}</span></p>
              <div className={classes.Icons}>
                {ActivityHelper.categoryIcon(this.props.activity)}
                {ActivityHelper.publicTypeIcon(this.props.activity)}
                {ActivityHelper.locomotionTypeIcon(this.props.activity)}
                {ActivityHelper.levelIcon(this.props.activity, t)}
                {ActivityHelper.durationIcon(this.props.activity)}
                {ActivityHelper.priceIcon(this.props.activity, t)}
              </div>
          </div>
        </div>
        <div className={classes.Favorites} >
          <input id={'FavoritesButton'+this.props.activity.id} 
            type='checkbox' 
            className={classes.VisualHidden} 
            onChange={(event) => this.favoritesChangeHandler(event)}
            checked={isSelected} />
          <label htmlFor={'FavoritesButton'+this.props.activity.id}>
              <img src={iconFavorites} alt={t('activity.add_to_selection')}/>
              <img src={iconFavoritesSelected} alt={t('activity.remove_from_selection')} />
          </label>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selection: state.selection,
    geozones: state.geozones,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setActivity: (activity) => dispatch(actions.setCurrentActivity(activity)),
    addToSelection: (activity) => dispatch(actions.addActivityToSelection(activity)),
    removeFromSelection: (activity) => dispatch(actions.removeActivityFromSelection(activity)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ActivityItem));