import React, { Component } from 'react';

import classes from './Layout.module.css';
import Auxiliary from '../Auxiliary/Auxiliary';

class Layout extends Component {

  render () {
    return (
      <Auxiliary>
        <main className={classes.GridContainer}>
          {this.props.children}
        </main>
      </Auxiliary>
    )
  }
}

export default Layout;
