import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as actions from '../../../store/actions';
import classes from './ActivityRelated.module.css';

class ActivityRelated extends Component {

  itemClickHandler = () => {

    this.props.setActivity(this.props.activity);
  }

  render() {

    return (
      <div id={'activity-related-'+this.props.activity.id} className={classes.ActivityRelated}>
        <div className={classes.Content} onClick={this.itemClickHandler}>
          <div className={classes.Left}>
            {this.props.activity.image_url ? <img src={this.props.activity.image_url} alt={this.props.activity.copyright} /> : null}
          </div>
          <div className={classes.Center}>
            <p className={classes.Title}>{this.props.activity.name}</p>
            <p id={"activity-" + this.props.activity.id + "-description"} className={classes.Description} ref={this.descriptionRef}><span>{this.props.activity.description_short}</span></p>
              <div className={classes.Icons}>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
}

const mapDispatchToProps = dispatch => {
  return {
    setActivity: (activity) => dispatch(actions.setRelatedActivity(activity)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ActivityRelated));