import React from 'react';

import classes from './FloatingMenu.module.css';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';

const floatingMenu = (props) => {

  return (
    <Auxiliary>
      {props.buttons ?
      <div className={classes.FloatingMenu}>
        {props.buttons.map(button => {
          return button;
        })}
      </div>
      : null
      }
    </Auxiliary>
  );
}

export default floatingMenu;