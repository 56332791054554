import React from 'react';
import * as Icons from './ActivityIcon/Icons';
import ActivityIcon from './ActivityIcon/ActivityIcon';

export const categoryIcon = (activity) => {

  if (activity.category) {
    const label = activity.category.detailed_label ? activity.category.detailed_label : activity.category.label;
    const icon = activity.category.icon;
    return (
      <ActivityIcon
        icon={icon}
        label={label}
      />
    );
  }
}

export const publicTypeIcon = (activity) => {

  if (activity.public_type) {
    return <ActivityIcon type='PublicType' icon={activity.public_type.icon.url} label={activity.public_type.label} />
  }
  return null;
}

export const levelIcon = (activity, t) =>  {
  
  const type = 'Level';
  const label = t('activity.level') + ' ' + activity.level + '/4';
  
  switch (activity.level) {
    case 1:
      return <ActivityIcon type={type} icon={Icons.LEVEL_1} label={label} />
    case 2:
      return <ActivityIcon type={type} icon={Icons.LEVEL_2} label={label} />
    case 3:
      return <ActivityIcon type={type} icon={Icons.LEVEL_3} label={label} />
    case 4:
      return <ActivityIcon type={type} icon={Icons.LEVEL_4} label={label} />
    default:
      return null;
  }
}

export const durationIcon = (activity) =>  {
  
  const type = 'Duration';
  let label = null;

  if (activity.duration > 0) {
    if (activity.duration < 60) {
      label = activity.duration + ' min';
    } else {
        const hours = Math.trunc(activity.duration/60);
        const minutes = Math.trunc(activity.duration % 60);
        if (minutes > 0) {
          label = hours + 'h' + minutes;
        } else {
          label = hours + 'h';
        }
    }
  }
  
  if (activity.duration > 0 && activity.duration <= 120) {
    return <ActivityIcon type={type} icon={Icons.DURATION_1} label={label} />
  } else if (activity.duration > 120 && activity.duration <= 240) {
    return <ActivityIcon type={type} icon={Icons.DURATION_2} label={label} />
  } else if (activity.duration > 240 && activity.duration <= 360) {
    return <ActivityIcon type={type} icon={Icons.DURATION_3} label={label} />
  } else if (activity.duration > 360) {
    return <ActivityIcon type={type} icon={Icons.DURATION_4} label={label} />
  }

  return null;
}

export const priceIcon = (activity, t) =>  {
  
  const type = 'Price';

  if (activity.price_avg === 0) {
    return <ActivityIcon type={type} icon={Icons.PRICE_1} label={t('activity.price_ranges.1')} />
  } else if (activity.price_avg > 0 && activity.price_avg < 20) {
    return <ActivityIcon type={type} icon={Icons.PRICE_2} label={t('activity.price_ranges.2')} />
  } else if (activity.price_avg >= 20 && activity.price_avg <= 50) {
    return <ActivityIcon type={type} icon={Icons.PRICE_3} label={t('activity.price_ranges.3_alt')} />
  } else if (activity.price_avg > 50) {
    return <ActivityIcon type={type} icon={Icons.PRICE_4} label={t('activity.price_ranges.4')} />
  }

  return null;
}

export const locomotionTypeIcon = (activity) => {

  if (activity.locomotion_type) {
    return <ActivityIcon type='LocomotionType' icon={activity.locomotion_type.icon.url} label={activity.locomotion_type.label} />
  }
  return null;
}

export const distance = (meters) => {

  if (meters > 0) {
    if (meters < 1000) {
      return meters + ' m';
    } else {
      if (meters % 1000 > 0) {
        return Number.parseFloat(meters/1000).toFixed(1) + ' km';
      } else {
        return meters/1000 + ' km';
      }
    }
  }
  return "0 m";
}

export const routeType = (route) => {

  let routeType = route.toLowerCase();
  routeType = routeType.charAt(0).toUpperCase() + routeType.slice(1);
  routeType = routeType.replace('_', '-');
  return routeType;
}