import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import i18n from '../../../i18n';
import * as actions from '../../../store/actions';

import classes from './ActivityDetails.module.css';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import FloatingMenu from '../../FloatingMenu/FloatingMenu';
import * as ActivityHelper from '../ActivitiesHelper';
import iconFavorites from '../../../assets/images/icon_favorites.svg';
import iconFavoritesSelected from '../../../assets/images/icon_favorites_selected.svg';
import { GAActivityDetails, GAAddToSelection, GARemoveFromSelection } from '../../Analytics/Firebase';
import ActivityRelated from '../ActivityRelated/ActivityRelated';

class ActivityRelatedDetails extends Component {

  state = {
    activity: null
  }

  componentDidMount() {

    const activityId = this.props.activity.id;
    const parameters = 'language=' + i18n.language;
    axios.get('/activity/' + activityId + '?' + parameters)
      .then(response => {
        if (response.data.data) {
          const activity = response.data.data;
          this.setState({
            activity: activity
          });
          GAActivityDetails(activity, '/activity/' + activityId);
        } else {
          this.props.setRelatedActivity(null);
        }
      })
      .catch(error => {
        this.props.setRelatedActivity(null);
      });
  }

  backClickHandler = () => {

    this.props.setRelatedActivity(null);
  }

  favoritesChangeHandler = (event) => {

    if (event.target.checked) {
      GAAddToSelection(this.state.activity);
      this.props.addToSelection(this.state.activity);
    } else {
      GARemoveFromSelection(this.state.activity);
      this.props.removeFromSelection(this.state.activity);
    }
  }

  escapedNewLineToLineBreakTag = (string) => {
    if (!string)
      return "";
    return string.split('\n').map((item, index) => {
      return (index === 0) ? item : [<br key={index} />, item]
    });
  }

  render() {

    const { t } = this.props;

    const animalsPolicy = {
      'refused' : t('no'),
      'accepted' : t('yes'),
      'n/a' : 'N/A',
    };

    const routeType = {
      'BOUCLE' : t('activity.route.boucle'),
      'ALLER_RETOUR' : t('activity.route.aller_retour'),
      'ALLER_ITINERANCE' : t('activity.route.aller_itinerance'),
    };

    let content = null;
    let isSelected = false;

    if (this.state.activity) {

      isSelected = this.props.selection.filter(a => a.id === this.state.activity.id).length > 0;

      let image = null;
      if (this.state.activity.images) {
        image = <img className={classes.Image} src={this.state.activity.images[0].url} alt={this.state.activity.images[0].copyright} />;
      }

      const categoryIcon = ActivityHelper.categoryIcon(this.state.activity);
      let category = null;
      if (categoryIcon) {
        category = 
          <div>
            <span className={classes.IconName}>{t('activity.category')}</span>
            {categoryIcon}
          </div>
      }
      
      const publicTypeIcon = ActivityHelper.publicTypeIcon(this.state.activity);
      let publicType = null;
      if (publicTypeIcon) {
        publicType = 
          <div>
            <span className={classes.IconName}>{t('activity.public')}</span>
            {publicTypeIcon}
          </div>
      }

      const levelIcon = ActivityHelper.levelIcon(this.state.activity, t);
      let level = null;
      if (levelIcon) {
        level = 
          <div>
            <span className={classes.IconName}>{t('activity.level')}</span>
            {levelIcon}
          </div>
      }

      const durationIcon = ActivityHelper.durationIcon(this.state.activity);
      let duration = null;
      if (durationIcon) {
        duration = 
          <div>
            <span className={classes.IconName}>{t('activity.duration')}</span>
            {durationIcon}
          </div>
      }

      const priceIcon = ActivityHelper.priceIcon(this.state.activity, t);
      let price = null;
      if (priceIcon) {
        price = 
          <div>
            <span className={classes.IconName}>{t('activity.price')}</span>
            {priceIcon}
          </div>
      }

      const locomotionTypeIcon = ActivityHelper.locomotionTypeIcon(this.state.activity);
      let locomotionType = null;
      if (locomotionTypeIcon) {
        locomotionType = 
          <div>
            <span className={classes.IconName}>{t('activity.locomotion')}</span>
            {locomotionTypeIcon}
          </div>
      }

      let extras = null;

      if (this.state.activity.nom_domaine) {

        extras = 
          <section>
            <h2>{this.state.activity.nom_domaine}</h2>
            {this.state.activity.desc_domaine ? <p>{this.state.activity.desc_domaine}</p> : null}
            <div className={classes.SkiRun}>
              <div className={classes.Green}>
                <b>{this.state.activity.nb_pistes_vertes_ouvertes}</b>
                /{this.state.activity.nb_pistes_vertes}
              </div>
              <div className={classes.Blue}>
                <b>{this.state.activity.nb_pistes_bleues_ouvertes}</b>
                /{this.state.activity.nb_pistes_bleues}
              </div>
              <div className={classes.Red}>
                <b>{this.state.activity.nb_pistes_rouges_ouvertes}</b>
                /{this.state.activity.nb_pistes_rouges}
              </div>
              <div className={classes.Black}>
                <b>{this.state.activity.nb_pistes_noires_ouvertes}</b>
                /{this.state.activity.nb_pistes_noires}
              </div>
            </div>
            <div className={[classes.Extras, classes.Center].join(' ')}>
              <div>
                <span className={classes.Name}>{t('activity.telecabine')}</span>
                <div className={classes.Value}>{this.state.activity.nb_telecabine}</div>
              </div>
              <div>
                <span className={classes.Name}>{t('activity.telesiege')}</span>
                <div className={classes.Value}>{this.state.activity.nb_telesiege}</div>
              </div>
              <div>
                <span className={classes.Name}>{t('activity.teleski')}</span>
                <div className={classes.Value}>{this.state.activity.nb_teleski}</div>
              </div>
            </div>
          </section>;

      } else if (this.state.activity.marking || this.state.activity.distance || this.state.activity.route_type) {

        extras = 
          <section>
            <h2>{t('activity.route.info')}</h2>
            {this.state.activity.marking ? <p>{this.state.activity.marking}</p> : null}
            <div className={classes.Extras}>
              {this.state.activity.route_type ?
              <div>
                <span className={classes.Name}>{t('activity.route.type')}</span>
                <div className={classes.Value}>
                  {routeType[this.state.activity.route_type] ? 
                    routeType[this.state.activity.route_type] 
                    : ActivityHelper.routeType(this.state.activity.route_type)}  
                </div>
              </div>
              : null}
              {this.state.activity.distance ?
              <div>
                <span className={classes.Name}>{t('activity.route.distance')}</span>
                <div className={classes.Value}>{ActivityHelper.distance(this.state.activity.distance)}</div>
              </div>
              : null}
            </div>
            
          </section>
      }

      let phone = null;
      if (this.state.activity.phone) {
        phone = this.state.activity.phone.split('|').map((item, index) => {
          return (index === 0) ? item : ' - ' + item;
        })
        phone = <p>{t('activity.phone')} : {phone}</p>
      }

      let email = null;
      if (this.state.activity.email) {
        email = this.state.activity.email.split('|').map((item, index) => {
          return (index === 0) ? item : ' - ' + item;
        })
        email = <p>{t('activity.email')} : {email}</p>
      }

      let website = null;
      if (this.state.activity.website) {
        website = this.state.activity.website.split('|').map((item, index) => {
          return (index === 0) ? item : ' - ' + item;
        })
        website = <p>{t('activity.web')} : {website}</p>
      }

      content = (
        <Auxiliary>
          <div className={classes.Top}>
            {image}
            <button className={classes.Back} onClick={this.backClickHandler}/>
            <div className={classes.Favorites} >
              <input id='RelatedFavoritesButton' 
                type='checkbox' 
                className={classes.VisualHidden} 
                onChange={(event) => this.favoritesChangeHandler(event)} 
                checked={isSelected} />
              <label htmlFor='RelatedFavoritesButton'>
                <img src={iconFavorites} alt={t('activity.add_to_selection')}/>
                <img src={iconFavoritesSelected} alt={t('activity.remove_from_selection')} />
              </label>
            </div>
            <h1>{this.state.activity.name}</h1>
          </div>
          <div className={classes.Details}>
            <section className={classes.Description}>
              <p>{this.escapedNewLineToLineBreakTag(this.state.activity.description_short)}</p>
              <p>{this.escapedNewLineToLineBreakTag(this.state.activity.description_long)}</p>
            </section>
            <section>
              <h2>{t('activity.summary')}</h2>
              <div className={classes.Icons}>
                {category}
                {publicType}
                {locomotionType}
                {level}
                {duration}
                {price}
              </div>
            </section>
            {extras}
            {this.state.activity.opening ?
              <section>
                <h2>{t('activity.opening')}</h2>
                <p>{this.escapedNewLineToLineBreakTag(this.state.activity.opening)}</p>
              </section>
              : null
            }
            {this.state.activity.price ?
              <section>
                <h2>{t('activity.price')}</h2>
                <p>{this.escapedNewLineToLineBreakTag(this.state.activity.price)}</p>
              </section>
              : null
            }
            {this.state.activity.disabled_accesses ?
              <section>
                <h2>{t('activity.accessibility')}</h2>
                {this.state.activity.disabled_accesses.map(da => {
                  return <p key={da.id}>{da.label}</p>
                })}
              </section>
              : null
            }
            <section>
              <h2>{t('activity.pets')}</h2>
              <p>{t('activity.pets_policy')} : {animalsPolicy[this.state.activity.accepted_animals_policy]}</p>
              {this.state.activity.accepted_animals_information ?
                <p>{this.escapedNewLineToLineBreakTag(this.state.activity.accepted_animals_information)}</p>
                : null
              }
            </section>
            {this.state.activity.phone || this.state.activity.website || this.state.activity.email ?
              <section className={classes.Contacts}>
                <h2>{t('activity.contact')}</h2>
                {phone}
                {email}
                {website}
              </section>
              : null
            }
            {this.state.activity.relations &&
              <section>
                <h2>{t('activity.relations')}</h2>
                {this.state.activity.relations.map(a => {
                    return <ActivityRelated key={a.id} activity={a} />
                  })}
              </section>
            }
          </div>
        </Auxiliary>
      );
    }

    return (
      <Auxiliary>
        <div className={classes.ActivityDetails}>
          <div className={classes.Container}>
            {content}
          </div>
          <FloatingMenu buttons={[
            <div key="FMRelatedBack" className={classes.FloatingMenuBack}>
              <button onClick={this.backClickHandler}/>
            </div>,
            <div key="FMRelatedFavorites" className={classes.FloatingMenuFavorites} >
              <input id='FloatingMenuRelatedFavoritesButton' 
                type='checkbox' 
                className={classes.VisualHidden} 
                onChange={(event) => this.favoritesChangeHandler(event)} 
                checked={isSelected} />
              <label htmlFor='FloatingMenuRelatedFavoritesButton'>
                <img src={iconFavorites} alt={t('activity.add_to_selection')}/>
                <img src={iconFavoritesSelected} alt={t('activity.remove_from_selection')} />
              </label>
            </div>
          ]} />
        </div>
      </Auxiliary>
    );
  }
}

const mapStateToProps = state => {
  return {
    selection: state.selection
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setRelatedActivity: (activity) => dispatch(actions.setRelatedActivity(activity)),
    addToSelection: (activity) => dispatch(actions.addActivityToSelection(activity)),
    removeFromSelection: (activity) => dispatch(actions.removeActivityFromSelection(activity)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ActivityRelatedDetails));
  