import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import classes from './Question.module.css';
import Auxiliary from '../../../hoc/Auxiliary/Auxiliary'
import ActivityItem from '../../Activities/ActivityItem/ActivityItem';
// import { GAPageView, GAEvent } from '../../Analytics/Analytics';
import { GAPageView, GAEvent } from '../../Analytics/Firebase';

class Question extends Component {

  timeLimit = 10;
  timer = null;

  state = {
    timeLeft: this.timeLimit
  }

  componentDidMount() {

    // GAPageView(this.props.question.question, '/question/' + this.props.question.question_id);
    GAPageView(this.props.question.question, 'question/' + this.props.question.question_id);
    this.countdownTimer();
  }

  componentWillUnmount() {

    clearInterval(this.timer);
  }

  componentDidUpdate(prevProps) {

    if (prevProps.question.question_id !== this.props.question.question_id) {
      this.setState({
        timeLeft: this.timeLimit
      }, () => {
        clearInterval(this.timer);
        this.countdownTimer();
      });
    }
  }

  countdownTimer = () => {

    this.timer = setInterval(() => {
      this.setState({
        timeLeft: this.state.timeLeft - 1,
      }, () => {
        if (this.state.timeLeft === 0) {
          this.onTimesUp();
        }
      });    
    }, 1000);
  }

  onTimesUp = () => {

    // GAEvent('questions', 'answer_displayed', 'Answer displayed');
    GAEvent('answer_displayed');
    clearInterval(this.timer);
  }
  
  render() {

    const { t } = this.props;
    
    let image = null;
    if (this.props.question.image) {
      image = <img src={this.props.question.image.url} alt={this.props.question.image.copyright} />;
    } else if (this.props.question.activities && this.props.question.activities.length > 0) {
      image = <img src={this.props.question.activities[0].image.url} alt={this.props.question.activities[0].image.copyright} />;
    }

    let answer = 
        <div className={classes.CountdownTimerWrapper}>
          <div className={classes.CountdownTimer}>
            {this.state.timeLeft}
          </div>
          <div className={[classes.CountdownTimerSpinner, classes.CountdownTimerSpinnerCircle].join(' ')}></div>
        </div>;
    let activities = null;
    if (this.state.timeLeft === 0) {
      answer = <p className={classes.Answer}>{this.props.question.answer}</p>;
      if (this.props.question.activities) {
        activities = 
          <Auxiliary>
            <h4>{t('questions.more')}</h4>
            {this.props.question.activities.map(activity => {
              return <ActivityItem key={activity.id} activity={activity} gaList={'Question' + this.props.question.question_id} />
            })}
          </Auxiliary>;
      }
    }

    return (
      <Auxiliary>
        <div className={classes.Question}>
          <div className={classes.Image}>{image}</div>
          <h4>{this.props.question.question}</h4>
          {answer}
        </div>
        <section className={classes.Activities}>
          {activities}
        </section>
      </Auxiliary>
    );
  }
}

export default withTranslation()(Question);