import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

import classes from "./Header.module.css";

const Header = (props) => {
  const { t } = useTranslation();

  const languagesClasses =
    i18n.language === "fr"
      ? [classes.Languages, classes.English]
      : [classes.Languages, classes.French];

  return (
    <header>
      <div className={classes.Header}>
        <div className={classes.Title}>
          <h1>
            {t("header.title.1")} <span>{t("header.title.2")}</span>
          </h1>
          <h2>{t("header.subtitle")}</h2>
        </div>
        <div className={classes.Actions}>
          <button
            className={languagesClasses.join(" ")}
            onClick={props.changeLanguage}
          />
          <button
            className={classes.Favorites}
            onClick={props.openSelection}
            disabled={props.selectionDisabled}
          />
        </div>
      </div>
      <nav className={classes.Navigation}>
        <ul>
          <li
            className={
              props.tabSelected === "activities" ? classes.active : null
            }
            onClick={() => props.tabClicked("activities")}
          >
            <div>
              <span className={classes.bigger}>{props.activitiesCounter}</span>
              <p>
                <span className="uppercase">
                  {props.activitiesCounter > 1
                    ? t("header.activities.title")
                    : t("header.activities.title-2")}
                </span>
                <br />
                {/*
                <span className={classes.smaller}>
                  {t("header.activities.text")}
                </span>
          */}
              </p>
            </div>
          </li>
          <li
            className={props.tabSelected === "services" ? classes.active : null}
            onClick={() => props.tabClicked("services")}
          >
            <div>
              <span className={classes.bigger}>{props.servicesCounter}</span>
              <p>
                <span className="uppercase">
                  {props.servicesCounter > 1
                    ? t("header.services.title")
                    : t("header.services.title-2")}
                </span>
                <br />
                {/**
                <span className={classes.smaller}>
                  {t("header.activities.text")}
                </span>
               */}
              </p>
            </div>
          </li>
          <li
            className={
              props.tabSelected === "questions" ? classes.active : null
            }
            onClick={() => props.tabClicked("questions")}
          >
            <div>
              <span className={classes.bigger}>?</span>
              <p>
                <span className="uppercase">{t("header.questions.title")}</span>
                <br />
                <span className={classes.smaller}>
                  {t("header.questions.text")}
                </span>
              </p>
            </div>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
