import level1 from '../../../assets/images/icon_level_1_selected.svg';
import level2 from '../../../assets/images/icon_level_2_selected.svg';
import level3 from '../../../assets/images/icon_level_3_selected.svg';
import level4 from '../../../assets/images/icon_level_4_selected.svg';

import duration1 from '../../../assets/images/icon_duration_1_selected.svg';
import duration2 from '../../../assets/images/icon_duration_2_selected.svg';
import duration3 from '../../../assets/images/icon_duration_3_selected.svg';
import duration4 from '../../../assets/images/icon_duration_4_selected.svg';

import price1 from '../../../assets/images/icon_price_1_selected.svg';
import price2 from '../../../assets/images/icon_price_2_selected.svg';
import price3 from '../../../assets/images/icon_price_3_selected.svg';
import price4 from '../../../assets/images/icon_price_4_selected.svg';

export const LEVEL_1 = level1;
export const LEVEL_2 = level2;
export const LEVEL_3 = level3;
export const LEVEL_4 = level4;

export const DURATION_1 = duration1;
export const DURATION_2 = duration2;
export const DURATION_3 = duration3;
export const DURATION_4 = duration4;

export const PRICE_1 = price1;
export const PRICE_2 = price2;
export const PRICE_3 = price3;
export const PRICE_4 = price4;