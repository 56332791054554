import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import classes from "../MenuItem.module.css";

import duration1Icon from "../../../assets/images/icon_duration_1.svg";
import duration1IconSelected from "../../../assets/images/icon_duration_1_selected.svg";
import duration2Icon from "../../../assets/images/icon_duration_2.svg";
import duration2IconSelected from "../../../assets/images/icon_duration_2_selected.svg";
import duration3Icon from "../../../assets/images/icon_duration_3.svg";
import duration3IconSelected from "../../../assets/images/icon_duration_3_selected.svg";
import duration4Icon from "../../../assets/images/icon_duration_4.svg";
import duration4IconSelected from "../../../assets/images/icon_duration_4_selected.svg";

import price1Icon from "../../../assets/images/icon_price_1.svg";
import price1IconSelected from "../../../assets/images/icon_price_1_selected.svg";
import price2Icon from "../../../assets/images/icon_price_2.svg";
import price2IconSelected from "../../../assets/images/icon_price_2_selected.svg";
import price3Icon from "../../../assets/images/icon_price_3.svg";
import price3IconSelected from "../../../assets/images/icon_price_3_selected.svg";
import price4Icon from "../../../assets/images/icon_price_4.svg";
import price4IconSelected from "../../../assets/images/icon_price_4_selected.svg";

import level1Icon from "../../../assets/images/icon_level_1.svg";
import level1IconSelected from "../../../assets/images/icon_level_1_selected.svg";
import level2Icon from "../../../assets/images/icon_level_2.svg";
import level2IconSelected from "../../../assets/images/icon_level_2_selected.svg";
import level3Icon from "../../../assets/images/icon_level_3.svg";
import level3IconSelected from "../../../assets/images/icon_level_3_selected.svg";
import level4Icon from "../../../assets/images/icon_level_4.svg";
import level4IconSelected from "../../../assets/images/icon_level_4_selected.svg";
import { ACTIVITIES_TAB } from "../../../containers/Home/Home";

const Wishes = (props) => {

  const { t } = useTranslation();

  const [categorySelected, setCategorySelected] = useState(props.categorySelected);
  const [subcategories, setSubcategories] = useState(props.categories.includes(props.categorySelected) && props.categorySelected ? props.categorySelected.subcategories : []);
  const [subcategorySelected, setSubcategorySelected] = useState(props.subcategorySelected);
  const [durationSelected, setDurationSelected] = useState(props.durationSelected);
  const [priceSelected, setPriceSelected] = useState(props.priceSelected);
  const [level, setLevel] = useState(props.levelSelected);
  const [activesFilters, setActivesFilters] = useState(
      !props.categories.includes(props.categorySelected) ? [] :
        props.subcategorySelected ? 
          props.subcategorySelected.filters : 
            props.categorySelected ? 
              props.categorySelected.filters : []
    );

  const categoriesChangeHandler = (event) => {
    let category = props.categories.find(
      (el) => el.slug === event.target.value
    );
    setCategorySelected(category);
    setSubcategories(category.subcategories);
    setSubcategorySelected(null);
    filtersChangeHandler(category.filters);
  };

  const subcategoriesChangeHandler = (event) => {
    let subcategory = subcategories.find(
      (el) => el.slug === event.target.value
    );
    setSubcategorySelected(subcategory);
    filtersChangeHandler(subcategory.filters);
  };

  const filtersChangeHandler = (filters) => {
    if (filters) {
      setActivesFilters(filters);
    } else {
      setActivesFilters([]);
    }
  };

  const durationChangeHandler = (event) => {
    if (event.target.value !== durationSelected) {
      setDurationSelected(event.target.value);
    } else {
      event.target.checked = false;
      setDurationSelected(null);
    }
  };

  const priceChangeHandler = (event) => {
    if (event.target.value !== priceSelected) {
      setPriceSelected(event.target.value);
    } else {
      event.target.checked = false;
      setPriceSelected(null);
    }
  };

  const levelChangeHandler = (event) => {
    if (event.target.value !== level) {
      setLevel(event.target.value);
    } else {
      event.target.checked = false;
      setLevel(null);
    }
  };

  const whishesData = () => {
    let durationMin = null;
    let durationMax = null;

    switch (durationSelected) {
      case "1":
        durationMin = 0;
        durationMax = 119;
        break;
      case "2":
        durationMin = 120;
        durationMax = 239;
        break;
      case "3":
        durationMin = 240;
        durationMax = 359;
        break;
      case "4":
        durationMin = 360;
        durationMax = null;
        break;
      default:
        durationMin = null;
        durationMax = null;
        break;
    }

    let priceMin = null;
    let priceMax = null;

    switch (priceSelected) {
      case "1":
        priceMin = null;
        priceMax = 0;
        break;
      case "2":
        priceMin = null;
        priceMax = 20;
        break;
      case "3":
        priceMin = null;
        priceMax = 50;
        break;
      case "4":
        priceMin = 51;
        priceMax = null;
        break;
      default:
        priceMin = null;
        priceMax = null;
        break;
    }

    return {
      categories: props.categories,
      category: categorySelected,
      subcategory: subcategorySelected,
      durationMin: durationMin,
      durationMax: durationMax,
      priceMin: priceMin,
      priceMax: priceMax,
      level: level,
    };
  };

  return (
    <div className={classes.MenuItem}>
      <section>
        <div className={classes.Row}>
          {
            <div>
              <span>
                {props.type === ACTIVITIES_TAB
                  ? t("wishes.categories.title.1")
                  : t("wishes.categories.title.3")}
              </span>
              <br />
              {props.type === ACTIVITIES_TAB && (
                <span className={classes.Smaller}>
                  {t("wishes.categories.title.2")}
                </span>
              )}
              <div className={classes.ButtonsList}>
                <ul>
                  {props.categories.map((category) => {
                      return (
                        <li key={category.id}>
                          <input
                            id={"button-category-" + category.id}
                            type="radio"
                            name="category"
                            className={classes.VisuallyHidden}
                            value={category.slug}
                            onClick={(event) => categoriesChangeHandler(event)}
                            defaultChecked={categorySelected ? categorySelected.slug === category.slug : false}
                          />
                          <label htmlFor={"button-category-" + category.id}>
                            <img
                              src={category.icon.empty}
                              alt={category.label}
                              width="50"
                              height="45"
                            />
                            <img
                              src={category.icon.url}
                              alt={category.label}
                              width="50"
                              height="45"
                            />
                          </label>
                          <span>{category.label}</span>
                        </li>
                      );
                  })}
                </ul>
              </div>
            </div>
          }
          {subcategories.length > 0 && (
            <div>
              <span>{t("wishes.type.title")}</span>
              <div className={classes.ButtonsList}>
                <ul>
                  {subcategories.map((subcategory) => {
                      return (
                        <li key={subcategory.id}>
                          <input
                            id={"button-subcategory-" + subcategory.id}
                            type="radio"
                            name="subcategory"
                            className={classes.VisuallyHidden}
                            value={subcategory.slug}
                            onClick={(event) => subcategoriesChangeHandler(event)}
                            defaultChecked={subcategorySelected ? subcategorySelected.slug === subcategory.slug : false}
                          />
                          <label
                            htmlFor={"button-subcategory-" + subcategory.id}
                          >
                            <img
                              src={subcategory.icon.empty}
                              alt={subcategory.label}
                              width="50"
                              height="45"
                            />
                            <img
                              src={subcategory.icon.url}
                              alt={subcategory.label}
                              width="50"
                              height="45"
                            />
                          </label>
                          <span>{subcategory.label}</span>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          )}
        </div>
      </section>
      <section>
        <div className={classes.Row}>
          {activesFilters && activesFilters.length > 0 && activesFilters.indexOf("duration") >= 0 && (
              <div>
                <span>{t("wishes.duration.title.1")}</span>
                <br />
                <span className={classes.Smaller}>
                  {t("wishes.duration.title.2")}
                </span>
                <div className={classes.ButtonsList}>
                  <ul>
                    <li>
                      <input
                        id="button-duration-1"
                        type="radio"
                        name="duration"
                        className={classes.VisuallyHidden}
                        value="1"
                        onClick={(event) => durationChangeHandler(event)}
                        defaultChecked={"1" === durationSelected}
                      />
                      <label htmlFor="button-duration-1">
                        <img
                          src={duration1Icon}
                          alt={t("activity.duration_ranges.1")}
                        />
                        <img
                          src={duration1IconSelected}
                          alt={t("activity.duration_ranges.1")}
                        />
                      </label>
                      <span>{t("activity.duration_ranges.1")}</span>
                    </li>
                    <li>
                      <input
                        id="button-duration-2"
                        type="radio"
                        name="duration"
                        className={classes.VisuallyHidden}
                        value="2"
                        onClick={(event) => durationChangeHandler(event)}
                        defaultChecked={"2" === durationSelected}
                      />
                      <label htmlFor="button-duration-2">
                        <img
                          src={duration2Icon}
                          alt={t("activity.duration_ranges.2")}
                        />
                        <img
                          src={duration2IconSelected}
                          alt={t("activity.duration_ranges.2")}
                        />
                      </label>
                      <span>{t("activity.duration_ranges.2")}</span>
                    </li>
                    <li>
                      <input
                        id="button-duration-3"
                        type="radio"
                        name="duration"
                        className={classes.VisuallyHidden}
                        value="3"
                        onClick={(event) => durationChangeHandler(event)}
                        defaultChecked={"3" === durationSelected}
                      />
                      <label htmlFor="button-duration-3">
                        <img
                          src={duration3Icon}
                          alt={t("activity.duration_ranges.3")}
                        />
                        <img
                          src={duration3IconSelected}
                          alt={t("activity.duration_ranges.3")}
                        />
                      </label>
                      <span>{t("activity.duration_ranges.3")}</span>
                    </li>
                    <li>
                      <input
                        id="button-duration-4"
                        type="radio"
                        name="duration"
                        className={classes.VisuallyHidden}
                        value="4"
                        onClick={(event) => durationChangeHandler(event)}
                        defaultChecked={"4" === durationSelected}
                      />
                      <label htmlFor="button-duration-4">
                        <img
                          src={duration4Icon}
                          alt={t("activity.duration_ranges.4")}
                        />
                        <img
                          src={duration4IconSelected}
                          alt={t("activity.duration_ranges.4")}
                        />
                      </label>
                      <span>{t("activity.duration_ranges.4")}</span>
                    </li>
                  </ul>
                </div>
              </div>
            )}

          {activesFilters && activesFilters.length > 0 && activesFilters.indexOf("level") >= 0 && (
              <div>
                <span>{t("profil.level.title")}</span>
                <br />
                <span className={classes.Smaller}>
                  {t("profil.level.text")}
                </span>
                <div className={classes.ButtonsList}>
                  <ul>
                    <li>
                      <input
                        id="button-level-1"
                        type="radio"
                        name="level"
                        className={classes.VisuallyHidden}
                        value="1"
                        onClick={(event) => levelChangeHandler(event)}
                        defaultChecked={"1" === level}
                      />
                      <label htmlFor="button-level-1">
                        <img src={level1Icon} alt={t("profil.level.level_1")} />
                        <img
                          src={level1IconSelected}
                          alt={t("profil.level.level_1")}
                        />
                      </label>
                      <div className={classes.Level}>
                        <div className={classes.Level1} />
                      </div>
                      <span>{t("profil.level.level_1")}</span>
                    </li>
                    <li>
                      <input
                        id="button-level-2"
                        type="radio"
                        name="level"
                        className={classes.VisuallyHidden}
                        value="2"
                        onClick={(event) => levelChangeHandler(event)}
                        defaultChecked={"2" === level}
                      />
                      <label htmlFor="button-level-2">
                        <img src={level2Icon} alt={t("profil.level.level_2")} />
                        <img
                          src={level2IconSelected}
                          alt={t("profil.level.level_2")}
                        />
                      </label>
                      <div className={classes.Level}>
                        <div className={classes.Level2} />
                      </div>
                      <span>{t("profil.level.level_2")}</span>
                    </li>
                    <li>
                      <input
                        id="button-level-3"
                        type="radio"
                        name="level"
                        className={classes.VisuallyHidden}
                        value="3"
                        onClick={(event) => levelChangeHandler(event)}
                        defaultChecked={"3" === level}
                      />
                      <label htmlFor="button-level-3">
                        <img src={level3Icon} alt={t("profil.level.level_3")} />
                        <img
                          src={level3IconSelected}
                          alt={t("profil.level.level_3")}
                        />
                      </label>
                      <div className={classes.Level}>
                        <div className={classes.Level3} />
                      </div>
                      <span>{t("profil.level.level_3")}</span>
                    </li>
                    <li>
                      <input
                        id="button-level-4"
                        type="radio"
                        name="level"
                        className={classes.VisuallyHidden}
                        value="4"
                        onClick={(event) => levelChangeHandler(event)}
                        defaultChecked={"4" === level}
                      />
                      <label htmlFor="button-level-4">
                        <img src={level4Icon} alt={t("profil.level.level_4")} />
                        <img
                          src={level4IconSelected}
                          alt={t("profil.level.level_4")}
                        />
                      </label>
                      <div className={classes.Level}>
                        <div className={classes.Level4} />
                      </div>
                      <span>{t("profil.level.level_4")}</span>
                    </li>
                  </ul>
                </div>
              </div>
            )}
        </div>
      </section>
      <section>
        {activesFilters && activesFilters.length > 0 && activesFilters.indexOf("cost") >= 0 && (
          <div>
            <span>{t("wishes.price.title.1")}</span>
            <br />
            <span className={classes.Smaller}>{t("wishes.price.title.2")}</span>
            <div className={classes.ButtonsList}>
              <ul>
                <li>
                  <input
                    id="button-price-1"
                    type="radio"
                    name="price"
                    className={classes.VisuallyHidden}
                    value="1"
                    onClick={(event) => priceChangeHandler(event)}
                    defaultChecked={"1" === priceSelected}
                  />
                  <label htmlFor="button-price-1">
                    <img src={price1Icon} alt={t("activity.price_ranges.1")} />
                    <img
                      src={price1IconSelected}
                      alt={t("activity.price_ranges.1")}
                    />
                  </label>
                  <span>{t("activity.price_ranges.1")}</span>
                </li>
                <li>
                  <input
                    id="button-price-2"
                    type="radio"
                    name="price"
                    className={classes.VisuallyHidden}
                    value="2"
                    onClick={(event) => priceChangeHandler(event)}
                    defaultChecked={"2" === priceSelected}
                  />
                  <label htmlFor="button-price-2">
                    <img src={price2Icon} alt={t("activity.price_ranges.2")} />
                    <img
                      src={price2IconSelected}
                      alt={t("activity.price_ranges.2")}
                    />
                  </label>
                  <span>{t("activity.price_ranges.2")}</span>
                </li>
                <li>
                  <input
                    id="button-price-3"
                    type="radio"
                    name="price"
                    className={classes.VisuallyHidden}
                    value="3"
                    onClick={(event) => priceChangeHandler(event)}
                    defaultChecked={"3" === priceSelected}
                  />
                  <label htmlFor="button-price-3">
                    <img src={price3Icon} alt={t("activity.price_ranges.3")} />
                    <img
                      src={price3IconSelected}
                      alt={t("activity.price_ranges.3")}
                    />
                  </label>
                  <span>{t("activity.price_ranges.3")}</span>
                </li>
                <li>
                  <input
                    id="button-price-4"
                    type="radio"
                    name="price"
                    className={classes.VisuallyHidden}
                    value="4"
                    onClick={(event) => priceChangeHandler(event)}
                    defaultChecked={"4" === priceSelected}
                  />
                  <label htmlFor="button-price-4">
                    <img src={price4Icon} alt={t("activity.price_ranges.4")} />
                    <img
                      src={price4IconSelected}
                      alt={t("activity.price_ranges.4")}
                    />
                  </label>
                  <span>{t("activity.price_ranges.4")}</span>
                </li>
              </ul>
            </div>
          </div>
        )}
      </section>
      <div className={classes.Actions}>
        <button
          className={classes.Close}
          onClick={() => props.submit(whishesData())}
        >
          {t("OK")}
        </button>
        <button
          className={classes.Next}
          onClick={() => props.submit(whishesData(), true)}
        >
          {t("wishes.next.1")}
          <span className={classes.Smaller}>{t("wishes.next.2")}</span>
        </button>
      </div>
    </div>
  );
};

export default Wishes;
