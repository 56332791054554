import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { I18nextProvider } from "react-i18next";
import thunk from 'redux-thunk';
import axios from 'axios';

import './index.module.css';
import App from './containers/App';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reducer from './store/reducer';
import i18n from './i18n';

axios.defaults.baseURL = "https://ccmg-demo.cheztoi.eu/local/api/v1/";

const logger = store => {
  return next => {
    return action => {
      // console.log('[Middleware] Dispatching', action);
      const result = next(action);
      // console.log('[Middleware] next state', store.getState());
      return result;
    }
  }
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancers(applyMiddleware(logger, thunk)));

const app = (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById('app'));
serviceWorkerRegistration.register();
