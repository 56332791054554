import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { DropTarget } from "react-drag-drop-container";
import mapboxgl from "mapbox-gl";
import bbox from "@turf/bbox";
import axios from "axios";
import * as actions from "../../store/actions";

import classes from "./Map.module.css";
import userLocationIcon from "../../assets/images/map_user_location.png";
import GeozoneMarker from "../../components/GeozoneMarker/GeozoneMarker";
import Switch from "../../components/UI/Switch/Switch";
// import { GAActivityImpression, GAActivityClick, GAEvent} from '../../components/Analytics/Analytics';
import { GAActivityClick, GAEvent } from "../../components/Analytics/Firebase";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWF1cmllbm5lLWdhbGliaWVyIiwiYSI6ImNrOGs4MDc2cjA3a3QzbG5veDh0cnE4bTcifQ.69tk4Jlg1SUmQaSpycRtBQ";

class Map extends Component {
  state = {
    weatherFiltered: true,
  };

  map = null;
  initialBounds = null;
  initialPitch = 45;
  markers = {};
  userLocationMarker = null;
  currentActivityMarker = null;
  routeLayerId = "route";

  mapEnabled = true;

  componentDidMount() {
    if (this.mapEnabled) {
      this.map = new mapboxgl.Map({
        container: this.mapContainer,
        style: "mapbox://styles/mapbox/satellite-streets-v10",
        center: [6.47265, 45.217395],
        zoom: 12,
        pitch: this.initialPitch,
        bearing: 80,
        antialias: true,
        attributionControl: false,
        logoPosition: "bottom-right",
      });

      this.map.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
        }),
        "bottom-left"
      );

      this.map.on("load", () => {
        // add the DEM source as a terrain layer with exaggerated height
        this.map.addSource('mapbox-dem', {
          'type': 'raster-dem',
          'url': 'mapbox://mapbox.mapbox-terrain-dem-v1'
        });
        this.map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.2 });
        this.initialLoad();
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.mapEnabled) {
      let shouldUpdate = false;

      if (nextState.weatherFiltered !== this.state.weatherFiltered) {
        shouldUpdate = true;
      }

      nextProps.geozones.forEach((nextGeozone, index) => {
        if (nextGeozone !== this.props.geozones[index]) {
          shouldUpdate = true;
        }
      });

      if (nextProps.currentActivity !== this.props.currentActivity) {
        shouldUpdate = true;
      }

      if (nextProps.searchParams !== this.props.searchParams) {
        shouldUpdate = true;
      }

      if (!shouldUpdate) {
        console.log("[Map.js] shouldComponentUpdate => FALSE");
      }

      return shouldUpdate;
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    if (this.mapEnabled) {

      if (!this.props.searchParams.user_location && this.userLocationMarker) {
        this.userLocationMarker.remove();
        this.userLocationMarker.off("dragend");
        this.userLocationMarker = null;
      }

      prevProps.geozones.forEach((prevGeozone, index) => {
        const prevForecasts = prevGeozone.forecasts
          ? prevGeozone.forecasts
          : [];
        const forecasts = this.props.geozones[index].forecasts
          ? this.props.geozones[index].forecasts
          : [];
        const prevActivities = prevGeozone.activities
          ? prevGeozone.activities
          : [];
        const activities = this.props.geozones[index].activities
          ? this.props.geozones[index].activities
          : [];

        if (
          prevGeozone.enabled !== this.props.geozones[index].enabled ||
          prevForecasts !== forecasts ||
          prevActivities !== activities
        ) {
          console.log(
            "[Map.js] Geozone updated",
            this.props.geozones[index].name
          );
          this.removeMarkers(this.props.geozones[index].id);
          this.loadMarkers(this.props.geozones[index]);
        }
      });

      if (this.props.currentActivity) {
        this.onActivitySelected(this.props.currentActivity);
      } else if (prevProps.currentActivity) {
        this.onActivityClosed(prevProps.currentActivity);
      }
    }
  }

  initialLoad = () => {

    this.initialBounds = new mapboxgl.LngLatBounds();
    this.props.geozones.forEach((geozone) => {
      this.markers["geozone" + geozone.id] = [];
      this.initialBounds.extend(this.loadMarkers(geozone));
    });
    this.fitBounds(this.initialBounds);
  }

  fitBounds = (bounds) => {
    if (bounds) {
      this.map.fitBounds(bounds, {
        padding: { top: 10, bottom: 100, left: 100, right: 100 },
        pitch: this.initialPitch
      });
    }
  };

  onActivitySelected = (activity) => {
    const geozoneMarkers = this.markers["geozone" + activity.geo_zone_id];
    if (geozoneMarkers) {
      this.currentActivityMarker = geozoneMarkers.find(
        (marker) => marker.getElement().id === "marker-activity-" + activity.id
      );
      if (this.currentActivityMarker) {
        this.map.fire("closeAllPopups");
        if (activity.kml) {
          this.addRoute(activity.kml.geojson);
        } else if (activity.flyTo) {
          this.flyTo(activity.latitude, activity.longitude, 16);
        }
        this.removeMarkers();
        this.map.once("moveend", () => {
          if (this.currentActivityMarker) {
            this.currentActivityMarker.togglePopup();
          }
        });
      }
    }
  };

  onActivityClosed = (activity = null) => {
    this.removeRoute();
    this.props.geozones.forEach((geozone) => {
      this.loadMarkers(geozone);
      this.fitBounds(this.initialBounds);
    });
    this.currentActivityMarker = null;
  };

  addRoute = (geojsonUrl) => {
    if (!this.map.getLayer(this.routeLayerId)) {
      axios.get(geojsonUrl).then((response) => {
        const data = response.data;

        this.map.addSource(this.routeLayerId, {
          type: "geojson",
          data: data,
        });

        this.map.addLayer({
          id: this.routeLayerId,
          type: "line",
          source: this.routeLayerId,
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#ed6841",
            "line-width": 4,
          },
        });

        this.fitBounds(bbox(data));
      });
    }
  };

  removeRoute = () => {
    if (this.map.getLayer(this.routeLayerId)) {
      this.map.removeLayer(this.routeLayerId);
      this.map.removeSource(this.routeLayerId);
    }
  };

  flyTo = (lat, lng, zoom = 16) => {
    this.map.flyTo({
      center: [lng, lat],
      zoom: zoom,
      essential: true,
    });
  };

  loadMarkers = (geozone) => {
    let geozoneBounds = new mapboxgl.LngLatBounds();
    if (geozone.activities) {
      geozone.activities.forEach((activity) => {
        const marker = this.createActivityMarker(activity);
        if (marker) {
          if (geozone.enabled) {
            if (this.currentActivityMarker) {
              if (marker.getElement().id !== this.currentActivityMarker.getElement().id) {
                marker.addTo(this.map);
                this.markers["geozone" + geozone.id].push(marker);
              }
            } else {
              marker.addTo(this.map);
              this.markers["geozone" + geozone.id].push(marker);
            }
          }

          const lngLat = new mapboxgl.LngLat(geozone.longitude, geozone.latitude);
          let distanceFromCenter = marker.getLngLat().distanceTo(lngLat) / 1000;
          if (distanceFromCenter < geozone.radius) {
            geozoneBounds.extend(marker.getLngLat());
          }
        }
      });

      if (!geozoneBounds.isEmpty()) {
        geozone.latitude = geozoneBounds.getCenter().lat;
        geozone.longitude = geozoneBounds.getCenter().lng;
      }
    } else {
      this.removeMarkers(geozone.id);
    }

    const geozoneCircleLayerId = "geozoneCircle" + geozone.id;

    let geozoneCircleLayer = this.map.getLayer(geozoneCircleLayerId);

    if (geozoneCircleLayer) {
      this.map.removeLayer(geozoneCircleLayerId);
    } else {
      this.map.addSource(
        geozoneCircleLayerId,
        this.createGeoJSONCircle(
          [geozone.longitude, geozone.latitude],
          geozone.radius
        )
      );
    }

    let geozoneCircle = this.map.getSource(geozoneCircleLayerId);
    const circlePointIndex = this.circlePosition(geozone.anchor);
    const coordinates =
      geozoneCircle._data.geometry.coordinates[circlePointIndex];
    const marker = this.createGeozoneMarker(
      geozone,
      coordinates[1],
      coordinates[0]
    );
    marker.addTo(this.map);
    this.markers["geozone" + geozone.id].push(marker);
    geozoneBounds.extend(marker.getLngLat());

    geozoneCircleLayer = {
      id: geozoneCircleLayerId,
      type: "line",
      source: geozoneCircleLayerId,
      layout: {},
      paint: {
        "line-color": geozone.color,
        "line-width": geozone.enabled ? 10 : 4,
        "line-opacity": 0.4,
      },
    };

    if (!geozone.enabled) {
      geozoneCircleLayer["paint"]["line-dasharray"] = [2, 1];
    }

    this.map.addLayer(geozoneCircleLayer);

    return geozoneBounds;
  };

  removeMarkers = (geozoneId = null) => {
    this.props.geozones.forEach((geozone) => {
      if (geozoneId === null || geozoneId === geozone.id) {
        this.markers["geozone" + geozone.id].forEach((m) => {
          if (this.currentActivityMarker) {
            if (m.getElement().id !== this.currentActivityMarker.getElement().id) {
              m.remove();
            }
          } else {
            m.remove();
          }
        });
      }
    });

    this.props.geozones.forEach((geozone) => {
      if (geozoneId === null || geozoneId === geozone.id) {
        if (this.currentActivityMarker) {
          const geozoneMarkers = this.markers["geozone" + geozone.id];
          this.markers["geozone" + geozone.id] = geozoneMarkers.filter((m) =>
            m.getElement().id === this.currentActivityMarker.getElement().id
          );
        } else {
          this.markers["geozone" + geozone.id] = [];
        }
      }
    });
  };

  createGeozoneMarker = (geozone, lat, lng) => {
    const markerId = "marker-geozone-" + geozone.id;
    const el = document.createElement("div");
    el.id = markerId;
    ReactDOM.render(
      <GeozoneMarker
        geozone={geozone}
        clicked={() => this.onGeozoneMarkerClicked(geozone.id)}
        onWeatherForecastClicked={() =>
          this.onWeatherForecastClicked(geozone, lat, lng)
        }
      />,
      el
    );

    const marker = new mapboxgl.Marker(el, {
      anchor: geozone.anchor,
    });

    marker.setLngLat([lng, lat]);

    return marker;
  };

  createActivityMarker = (activity) => {
    const mapIcon = activity.category !== undefined ? activity.category.map_icon : activity.parent_category.map_icon;
    if (!mapIcon)
      return null;
    const popupId = "popup-marker-activity-" + activity.id;
    const elPopup = document.createElement("div");
    elPopup.id = popupId;
    elPopup.innerHTML = activity.name;
    elPopup.addEventListener("click", () => {
      GAActivityClick(activity, "Map");
      if (
        this.props.currentActivity &&
        this.props.currentActivity.id !== activity.id
      ) {
        this.props.setActivity(null);
        setTimeout(() => {
          this.props.setActivity({
            ...activity,
            flyTo: true,
          });
        }, 300);
      } else {
        this.props.setActivity({
          ...activity,
          flyTo: true,
        });
      }
    });

    const popup = new mapboxgl.Popup({
      offset: parseFloat(mapIcon.size.height),
      closeButton: false,
      closeOnClick: true,
      className: classes.Popup,
    }).setDOMContent(elPopup);

    this.map.on("closeAllPopups", () => {
      popup.remove();
    });

    popup.on("open", () => {
      // GAActivityImpression(activity, 'Map');
      const item = document.getElementById("activity-item-" + activity.id);
      if (item) {
        item.scrollIntoView({
          block: "start",
          behavior: "smooth",
          inline: "nearest",
        });
      }
    });

    const markerId = "marker-activity-" + activity.id;
    const el = document.createElement("div");
    el.id = markerId;
    el.style.backgroundImage = "url(" + mapIcon.url + ")";
    el.style.backgroundSize = "contain";
    el.style.backgroundRepeat = "no-repeat";
    el.style.width = mapIcon.size.width + "px";
    el.style.height = mapIcon.size.height + "px";

    if (activity.bad_weather && this.state.weatherFiltered) {
      el.className = "weather marker-disabled";
    } else if (activity.bad_weather) {
      el.className = "weather";
    }

    const marker = new mapboxgl.Marker(el, {
      anchor: "bottom",
    });

    marker.setLngLat([activity.longitude, activity.latitude]);
    marker.setPopup(popup);

    return marker;
  };

  createGeoJSONCircle = (center, radiusInKm, points) => {
    if (!points) points = 64;

    const coords = {
      latitude: center[1],
      longitude: center[0],
    };

    const km = radiusInKm;

    const ret = [];
    const distanceX =
      km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    const distanceY = km / 110.574;

    let theta, x, y;
    for (let i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);
      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    return {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: ret,
        },
      },
    };
  };

  circlePosition = (anchor) => {
    let position = 0;

    switch (anchor) {
      case "bottom-left":
        position = 8;
        break;
      case "bottom-right":
        position = 24;
        break;
      case "top-right":
        position = 40;
        break;
      case "top-left":
        position = 56;
        break;
      default:
        position = 0;
        break;
    }

    return position;
  };

  updateActivitiesList = (geozones) => {
    const enabledGeozones = geozones.filter((g) => g.enabled === true);

    let searchParams;
    if (enabledGeozones.length === geozones.length) {
      searchParams = {
        ...this.props.searchParams,
        geozones: null,
      };
    } else {
      searchParams = {
        ...this.props.searchParams,
        geozones: enabledGeozones.map((g) => {
          return g.id;
        }),
      };
    }
    this.props.updateSearch(searchParams);
  };

  onGeozoneMarkerClicked = (id) => {
    const geozoneIndex = this.props.geozones.findIndex((g) => {
      return g.id === id;
    });

    const geozone = {
      ...this.props.geozones[geozoneIndex],
    };

    geozone.enabled = !geozone.enabled;

    const geozones = [...this.props.geozones];
    geozones[geozoneIndex] = geozone;

    this.props.setGeozones(geozones);
    this.updateActivitiesList(geozones);

    if (geozone.enabled) {
      // GAEvent('map', 'geozone_enable', geozone.name + ' enable');
      GAEvent("geozone_enable");
    } else {
      // GAEvent('map', 'geozone_disable', geozone.name + ' disable');
      GAEvent("geozone_disable");
    }
  };

  onWeatherSwitchChange = (event) => {
    this.setState(
      {
        weatherFiltered: !this.state.weatherFiltered,
      },
      () => {
        // GAEvent('map', this.state.weatherFiltered ? 'weather_filter_enable' : 'weather_filter_disable', this.state.weatherFiltered ? 'Weather filter enable' : 'Weather filter disable');
        GAEvent(
          this.state.weatherFiltered
            ? "weather_filter_enable"
            : "weather_filter_disable"
        );
        const weatherMarkers = Array.prototype.slice.call(
          ReactDOM.findDOMNode(this.mapContainer).getElementsByClassName(
            "weather"
          )
        );
        weatherMarkers.forEach((markerElement) => {
          if (this.state.weatherFiltered) {
            markerElement.classList.add("marker-disabled");
          } else {
            markerElement.classList.remove("marker-disabled");
          }
        });
      }
    );
  };

  onWeatherForecastClicked = (geozone, lat, lng) => {
    // GAEvent('map', 'geozone_forecast', geozone.name + ' forecast');
    GAEvent("geozone_forecast");
    this.map.flyTo({
      center: [lng, lat],
      speed: 0.2,
    });
  };

  onUserLocationDrop = (event) => {
    const coordinates = this.map.unproject([event.x, event.y]);
    if (!this.userLocationMarker) {
      // GAEvent('map', 'add_user_location', 'Add user location');
      GAEvent("add_user_location");
      const el = document.createElement("div");
      el.id = "user-location";
      el.style.backgroundImage = "url(" + userLocationIcon + ")";
      el.style.backgroundSize = "contain";
      el.style.width = "58px";
      el.style.height = "70px";
      this.userLocationMarker = new mapboxgl.Marker(el, {
        anchor: "bottom",
        draggable: true,
      });
      this.userLocationMarker.setLngLat(coordinates);
      this.userLocationMarker.addTo(this.map);
    } else {
      // GAEvent('map', 'update_user_location', 'Update user location');
      GAEvent("update_user_location");
      this.userLocationMarker.setLngLat(coordinates);
    }
    this.props.setUserLocation([coordinates.lng, coordinates.lat]);
    this.userLocationMarker.on("dragend", () => {
      const coordinates = this.userLocationMarker.getLngLat();
      const searchParams = {
        ...this.props.searchParams,
        user_location: [coordinates.lng, coordinates.lat],
      };
      this.props.updateSearch(searchParams);
    });
  };

  render() {
    const { t } = this.props;

    return (
      <div className={classes.Map}>
        <DropTarget targetKey="userLocation" onHit={this.onUserLocationDrop}>
          <div
            ref={(el) => (this.mapContainer = el)}
            className={classes.MapContent}
          />
        </DropTarget>
        <div className={classes.WeatherSwitchContainer}>
          <div className={classes.WeatherSwitch}>
            <div className={classes.SwitchContainer}>
              <Switch
                isOn={this.state.weatherFiltered}
                changed={this.onWeatherSwitchChange}
              />
            </div>
            <p>{t("map.weather_filter")}</p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.error,
    // tutorial: state.tutorial,
    geozones: state.geozones,
    currentActivity: state.currentActivity,
    searchParams: state.searchParams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGeozones: (geozones) => dispatch(actions.setGeozones(geozones)),
    setActivity: (activity) => dispatch(actions.setCurrentActivity(activity)),
    setUserLocation: (lngLat) => dispatch(actions.setUserLocation(lngLat)),
    updateSearch: (params) => dispatch(actions.updateSearch(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Map));
