import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import * as actions from '../../store/actions';

import classes from './Selection.module.css';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import ActivityItem from '../../components/Activities/ActivityItem/ActivityItem';
import FloatingMenu from '../../components/FloatingMenu/FloatingMenu';
// import { GAPageView, GAValidateSelection, GAEditSelection, GAEraseSelection } from '../../components/Analytics/Analytics';
import { GAPageView, GAValidateSelection, GAEditSelection, GAEraseSelection } from '../../components/Analytics/Firebase';

const SELECTION_URL = 'https://ccmg.cheztoi.eu/selection?';

class Selection extends Component {

  state = {
    transactionId: null,
    finished: false,
    qrcode: null,
  }

  componentDidMount() {

    GAPageView('Selection', 'selection');
  }

  componentWillUnmount() {

    GAPageView('Home');
  }

  backClickHandler = () => {
    
    this.props.closeSelection();
  }

  validateClickHandler = () => {

    const transactionId = GAValidateSelection(this.props.selection);
    
    const activitiesIds = this.props.selection.map(activity => {
      return activity.id;
    });
    const url = SELECTION_URL + 'uid=' + this.props.userId + '&activities=' + activitiesIds.join(',');
    const qrcode = <QRCode value={url} renderAs='svg' />;

    this.setState({
      transactionId: transactionId,
      finished: true,
      qrcode: qrcode
    });
  }

  editClickHandler = () => {

    GAEditSelection(this.state.transactionId, this.props.selection);

    this.setState({
      transactionId: null,
      finished: false,
      qrcode: null
    });
  }

  doneClickHandler = () => {

    GAEraseSelection();
    this.props.resetApp();
  }

  render() {

    const { t } = this.props;

    let content = null;
    let qrcodeContent = null;

    if (this.props.selection.length === 0) {
      
      content = <div className={classes.Empty}>{t('selection.empty')}</div>

    } else {

      if (this.state.finished) {

        qrcodeContent = 
          <div className={classes.QrCodeContainer}>
            <p><strong>{t('selection.done.text.1')}</strong></p>
            <div className={classes.QrCode}>
              <span>{t('selection.done.qrcode.1')}<br /><small>{t('selection.done.qrcode.2')}</small></span>
              {this.state.qrcode}
              <div className={classes.Actions}>
                <p><Trans i18nKey="selection.done.text.2" /></p>
                <button className={classes.Simple} onClick={this.editClickHandler}>{t('edit')}</button>
                <button onClick={this.doneClickHandler}>{t('finish')}</button>
              </div>
            </div>
          </div>;

        content = qrcodeContent;

      } else {

        qrcodeContent = 
          <div className={classes.QrCodeContainer}>
            <p>{t('selection.in_progress')}</p>
            <button onClick={this.validateClickHandler}>{t('validate')}</button>
          </div>;

        content = 
          <Auxiliary>
            {qrcodeContent}
            <div className={classes.Activities}>
              {this.props.selection.map(activity => {
                return <ActivityItem key={activity.id} activity={activity} showWeatherForecast={true} gaList='Selection' />
              })}
            </div>
          </Auxiliary>;
      }
    }

    return (
      <div className={classes.Selection}>
        <header>
          <button className={classes.Back} onClick={this.backClickHandler}/>
          <h1>{t('selection.title')}</h1>
        </header>
        <div className={classes.Container}>
          {content}
        </div>
        <FloatingMenu buttons={[
          <div key="FMBack" className={classes.FloatingMenuBack}>
            <button onClick={this.backClickHandler}/>
          </div>
        ]} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userId: state.userId,
    selection: state.selection
  };
}

const mapDispatchToProps = dispatch => {
  return {
    closeSelection: () => dispatch(actions.toggleSelection(false)),
    resetApp: () => dispatch(actions.resetApp()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Selection));