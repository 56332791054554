import React from 'react';
import { useTranslation } from 'react-i18next';

import classes from './Loader.module.css';

const Loader = (props) => {

  const { t } = useTranslation();

  return (
    <div className={classes.Loader}>
        <span>{t('loading')}</span>
    </div>
  );
}

export default Loader;
