import React, { Component } from 'react';
import { connect } from 'react-redux';
import Transition from 'react-transition-group/Transition';

import classes from './SidePanel.module.css';
import Home from '../Home/Home';
import Selection from '../Selection/Selection';
import ActivityDetails from '../../components/Activities/ActivityDetails/ActivityDetails';
import ActivityRelatedDetails from '../../components/Activities/ActivityDetails/ActivityRelatedDetails';

class SidePanel extends Component {

  
  render() {
    
    const transitionStyles = {
      entering: {
        transform: 'translateX(+100%)'
      },
      entered: {
        transform: 'translateX(0)',
        zIndex: 2
      },
      exiting: {
        transform: 'translateX(+100%)',
        zIndex: 2
      },
      exited: {
        transform: 'translateX(+100%)',
        zIndex: 2
      },
    };

    return (
      <div className={classes.SidePanel}>
        <div className={classes.StackedItem}>
          <Home />
        </div>
        <Transition in={this.props.selectionOpened} timeout={300} mountOnEnter unmountOnExit>
          {state => (
            <div className={classes.StackedItem} style={{...transitionStyles[state]}}>
              <Selection />
            </div>
          )}          
        </Transition>
        <Transition in={this.props.currentActivity !== null} timeout={300} mountOnEnter unmountOnExit>
          {state => (
            <div className={classes.StackedItem} style={{...transitionStyles[state]}}>
              <ActivityDetails />
            </div>
          )}          
        </Transition>
        <Transition in={this.props.relatedActivity !== null} timeout={300} mountOnEnter unmountOnExit>
          {state => (
            <div className={classes.StackedItem} style={{...transitionStyles[state]}}>
              <ActivityRelatedDetails activity={this.props.relatedActivity} />
            </div>
          )}          
        </Transition>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentActivity: state.currentActivity,
    relatedActivity: state.relatedActivity,
    selectionOpened: state.selectionOpened
  };
}

export default connect(mapStateToProps)(SidePanel);