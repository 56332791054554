import i18n from 'i18next';
import fr from './fr/common.json';
import en from './en/common.json';

const options = {
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    debug: false,
    lng: 'fr',
    resources: {
      fr: {
        common: fr,
      },
      en: {
        common: en,
      },
    },
    fallbackLng: 'fr',
    ns: ['common'],
    defaultNS: 'common',
};

i18n.init(options);

// TODO : https://www.i18next.com/overview/api#changelanguage

export default i18n;