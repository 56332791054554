import firebase from 'firebase/app';
import 'firebase/analytics';
import i18n from '../../i18n';

const analyticsEnabled = process.env.NODE_ENV === 'production';

const DEFAULT_CONFIG = {
    apiKey: "AIzaSyDmyuYcimklsLSoxEFJj2e1zrNs1Tho8WY",
    authDomain: "cc-maurienne-galibier.firebaseapp.com",
    databaseURL: "https://cc-maurienne-galibier.firebaseio.com",
    projectId: "cc-maurienne-galibier",
    storageBucket: "cc-maurienne-galibier.appspot.com",
    messagingSenderId: "589718394361",
    appId: "1:589718394361:web:9b622ba8aa0c6f9c409928",
    measurementId: "G-M5CDHM55M7"
};

export const GAInit = (config = DEFAULT_CONFIG) => {

    if (!analyticsEnabled) return;

    firebase.initializeApp(DEFAULT_CONFIG);
    firebase.analytics();
}

export const GASessionStart = () => {

    const userId = Math.floor(Date.now() / 1000);
    
    if (!analyticsEnabled) return userId;

    firebase.analytics().setUserId(userId);
    firebase.analytics().setUserProperties({
        language: i18n.language
    });
    GAPageView('Home');
    return userId;
}

export const GASessionEnd = () => {

    if (!analyticsEnabled) return;

    firebase.analytics().setUserProperties(null);
    firebase.analytics().setUserId(null);
    GAPageView('Screensaver');
}

export const GALanguage = () => {

    if (!analyticsEnabled) return;

    firebase.analytics().setUserProperties({
        language: i18n.language
    });
    firebase.analytics().logEvent('language_change');
}

export const GAPageView = (title) => {

    if (!analyticsEnabled) return;

    firebase.analytics().logEvent(firebase.analytics.EventName.SCREEN_VIEW, {
        screen_name: title,
    });
}

export const GAUserProperties = (userProperties) => {

    if (!analyticsEnabled) return;

    firebase.analytics().setUserProperties(userProperties);
    firebase.analytics().logEvent('profil_update');
}

export const GAActivitiesImpression = (items, list = 'Activities') => {

    if (!analyticsEnabled) return;

    const params = {
        item_list_id: 'L' + list,
        item_list_name: list,
        items: items.map(i => {
            const item = {
                item_id: i.id,
                item_name: i.name,
                item_category: i.category.label,
                item_category2: i.category.detailed_label,
            };
            return item;
        })
     };
     
     firebase.analytics().logEvent(firebase.analytics.EventName.VIEW_ITEM_LIST, params);
}

export const GAActivityClick = (item, list = 'Activities') => {

    if (!analyticsEnabled) return;

    const params = {
        item_list_id: 'L'+list,
        item_list_name: list,
        items: [{
            item_id: item.id,
            item_name: item.name,
            item_category: item.category.label,
            item_category2: item.category.detailed_label,
         }]
     };
     
     firebase.analytics().logEvent(firebase.analytics.EventName.SELECT_ITEM, params);
}

export const GAActivityDetails = (item, location) => {

    if (!analyticsEnabled) return;

    const params = {
        items: [{
            item_id: item.id,
            item_name: item.name,
            item_category: item.category.label,
            item_category2: item.category.detailed_label,
         }]
     };

    firebase.analytics().logEvent(firebase.analytics.EventName.VIEW_ITEM, params);
    GAPageView(item.name);
}

export const GAAddToSelection = (item) => {

    if (!analyticsEnabled) return;

    const params = {
        items: [{
            item_id: item.id,
            item_name: item.name,
            item_category: item.category.label,
            item_category2: item.category.detailed_label,
            quantity: 1,
         }]
     };

     firebase.analytics().logEvent(firebase.analytics.EventName.ADD_TO_CART, params);
}

export const GARemoveFromSelection = (item) => {

    if (!analyticsEnabled) return;

    const params = {
        items: [{
            item_id: item.id,
            item_name: item.name,
            item_category: item.category.label,
            item_category2: item.category.detailed_label,
            quantity: 1,
         }]
     };

     firebase.analytics().logEvent(firebase.analytics.EventName.REMOVE_FROM_CART, params);
}

export const GAValidateSelection = (items) => {

    const transactionId = 'T' + Math.floor(Date.now() / 1000);

    if (!analyticsEnabled) return transactionId;

    const params = {
        transaction_id: transactionId,
        items: items.map(i => {
            const item = {
                item_id: i.id,
                item_name: i.name,
                item_category: i.category.label,
                quantity: 1,
            };
            return item;
        })
     };
     
     firebase.analytics().logEvent(firebase.analytics.EventName.PURCHASE, params);

    return transactionId;
}

export const GAEditSelection = (transactionId) => {

    if (!analyticsEnabled) return;

    const params = {
        transaction_id: transactionId,
     };
     
     firebase.analytics().logEvent(firebase.analytics.EventName.REFUND, params);
}

export const GAEraseSelection = () => {

    if (!analyticsEnabled) return;

    firebase.analytics().logEvent('selection_done');
}

export const GAEvent = (action) => {

    if (!analyticsEnabled) return;

    firebase.analytics().logEvent(action);
}