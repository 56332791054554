import React, { Component } from "react";
import { connect } from "react-redux";
import Transition from "react-transition-group/Transition";
import i18n from "../../i18n";
import * as actions from "../../store/actions";
import { sortActivitiesBy } from "../../utility/utility";

import classes from "./Home.module.css";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Header from "../../components/Header/Header";
import FloatingMenu from "../../components/FloatingMenu/FloatingMenu";
import Activities from "../../components/Activities/Activities";
import Questions from "../../components/Questions/Questions";
import Welcome from "../../components/Welcome/Welcome/Welcome";
// import { GALanguage } from '../../components/Analytics/Analytics';
import { GALanguage } from "../../components/Analytics/Firebase";

export const ACTIVITIES_TAB = "activities";
export const SERVICES_TAB = "services";
export const QUESTIONS_TAB = "questions";

class Home extends Component {
  state = {
    tabSelected: this.props.tutorial ? null : ACTIVITIES_TAB,
    activities: this.props.activities,
    services: this.props.services
  };

  componentDidUpdate(prevProps) {
    if (prevProps.initialized && !this.props.initialized) {
      if (this.state.tabSelected !== ACTIVITIES_TAB) {
        this.setState({
          tabSelected: ACTIVITIES_TAB,
        });
      }
    } else if (prevProps.tutorial !== this.props.tutorial) {
      if (this.state.tabSelected !== ACTIVITIES_TAB) {
        this.setState({
          tabSelected: ACTIVITIES_TAB,
        });
      }
    } else if (prevProps.activities !== this.props.activities) {
      this.setState({
        activities: this.sortActivities(this.props.activities),
      });
    } else if (prevProps.services !== this.props.services) {
      this.setState({
        services: this.sortActivities(this.props.services),
      });
    } else if (prevProps.sortSelected !== this.props.sortSelected) {
      this.setState({
        activities: this.sortActivities(this.state.activities),
        services: this.sortActivities(this.state.services)
      });
    }
  }

  sortActivities = (activities) => {
    return activities.sort(sortActivitiesBy(this.props.sortSelected));
  };

  tabClickHandler = (tab) => {
    if (tab !== this.state.tabSelected && !this.props.tutorial) {
      this.setState({
        tabSelected: tab,
      });
    }
  };

  openSelectionHandler = () => {
    this.props.openSelection();
  };

  changeLanguageHandler = () => {
    const language = i18n.language === "fr" ? "en" : "fr";
    i18n.changeLanguage(language).then((t) => {
      // GALanguage(language);
      GALanguage();
      this.props.switchLanguage();
    });
  };

  render() {

    const selectionDisabled = this.props.selection.length === 0;

    const floatingMenuButtons = [
      <div key="FMHelp" className={classes.FloatingMenuHelp}>
          <button onClick={() => this.props.setTutorial(true)}>?</button>
      </div>
    ];

    if (this.props.searchParams.has_search) {
      floatingMenuButtons.push(
        <div key="FMReset" className={classes.FloatingMenuReset}>
          <button onClick={this.props.resetSearch} />
        </div>
      );
    }

    if (!selectionDisabled) {
      floatingMenuButtons.push(
        <div key="FMFavorites" className={classes.FloatingMenuFavorites}>
          <button onClick={this.openSelectionHandler} disabled={selectionDisabled} />
        </div>
      );
    }

    let floatingMenu = null;
    if (floatingMenuButtons.length > 0) {
      floatingMenu = <FloatingMenu buttons={[floatingMenuButtons]} />;
    }

    return (
      <div className={classes.Home}>
        <Header
          selectionDisabled={selectionDisabled}
          changeLanguage={this.changeLanguageHandler}
          openSelection={this.openSelectionHandler}
          tabSelected={this.state.tabSelected}
          tabClicked={this.tabClickHandler}
          activitiesCounter={this.props.activitiesCount}
          servicesCounter={this.props.servicesCount}
        />
        <Auxiliary>
          {this.props.tutorial ? (
            <Transition timeout={300}>
              {(state) => <Welcome transitionState={state} />}
            </Transition>
          ) : (
            <Auxiliary>
              <Transition
                in={this.state.tabSelected === ACTIVITIES_TAB || this.state.tabSelected === SERVICES_TAB}
                timeout={300}
              >
                {(state) => (
                  <Activities
                    type={this.state.tabSelected}
                    transitionState={state}
                    activities={this.state.tabSelected === SERVICES_TAB ? this.props.services : this.props.activities}
                    sortSelected={this.props.sortSelected}
                  />
                )}
              </Transition>
              <Transition
                in={this.state.tabSelected === QUESTIONS_TAB}
                timeout={300}
              >
                {(state) => <Questions transitionState={state} />}
              </Transition>
              {floatingMenu}
            </Auxiliary>
          )}
        </Auxiliary>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialized: state.initialized,
    tutorial: state.tutorial,
    error: state.error,
    sortSelected: state.menuSortSelected,
    activities: state.activities,
    services: state.services,
    activitiesCount: state.activitiesCount,
    servicesCount: state.servicesCount,
    selection: state.selection,
    searchParams: state.searchParams
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openSelection: () => dispatch(actions.toggleSelection(true)),
    switchLanguage: () => dispatch(actions.switchLanguage()),
    resetSearch: () => dispatch(actions.resetSearch()),
    setTutorial: (active) => dispatch(actions.setTutorial(active)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
