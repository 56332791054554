import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import classes from './Activities.module.css';
import Menu from '../../components/Menu/Menu';
import ActivityItem from './ActivityItem/ActivityItem';
import * as menuItem from '../../components/Menu/Menu';
import Auxiliary from '../../hoc/Auxiliary/Auxiliary'

import { ACTIVITIES_TAB } from "../../containers/Home/Home";

class Activities extends Component {

  state = {
    type: ACTIVITIES_TAB
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type !== this.props.type) {
      this.setState({
        type: this.props.type
      })
    }
  }

  render() {

    const { t } = this.props;
    let currentSectionId = null;
    let content = null;

    if (this.props.activities && this.props.activities.length === 0) {
      content = (
        <div className={classes.NoResult}>
          {t("no_result")}
          <br />
          <small>{t("no_result_help")}</small>
        </div>
      );
    } else {
      content = this.props.activities && this.props.activities.map((activity) => {
        let sectionDiv = null;
        if (this.props.sortSelected === menuItem.CATEGORY_SORT) {
          const sectionId = activity.category.id;
          if (currentSectionId !== sectionId) {
            currentSectionId = sectionId;
            sectionDiv = (
              <div className={classes.ActivitiesSection}>
                <img
                  src={activity.category.icon.url}
                  alt={activity.category.label}
                />
                <span>{activity.category.label}</span>
              </div>
            );
          }
        }
        if (sectionDiv) {
          return (
            <Auxiliary key={activity.id}>
              {sectionDiv}
              <ActivityItem activity={activity} gaList="Activities" />
            </Auxiliary>
          );
        } else {
          return (
            <ActivityItem
              key={activity.id}
              activity={activity}
              gaList="Activities"
            />
          );
        }
      });
    }

    return (
      <Auxiliary>
        <Menu transitionState={this.props.transitionState} type={this.state.type} activities={this.props.activities} />
        <div id="list-container" className={[classes.Activities, this.props.transitionState].join(' ')}>
          <div className={classes.ActivitiesList}>
            {content}
          </div>
        </div>
      </Auxiliary>
    );
  }
}

export default withTranslation()(Activities);