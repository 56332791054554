import * as actionTypes from "./actionTypes";
import axios from "axios";
import i18n from "../../i18n";
import { sortOn } from "../../utility/utility";

export const initApp = () => {
  return (dispatch) => {
    let parameters = "language=" + i18n.language;
    const activitiesQuery = "/activities?" + parameters;
    axios
      .get(activitiesQuery)
      .then((response) => {        
        const activitiesCount = response.data.count_activities
          ? response.data.count_activities
          : 0;
        const servicesCount = response.data.count_services
          ? response.data.count_services
          : 0;
        const activities = response.data.activities
          ? response.data.activities
          : [];
        const services = response.data.services
          ? response.data.services
          : [];
        const allActivities = activities.concat(services);  
    
        let activitiesCategories = [];
        for (const activity of activities) {
          var activityMainCategory = activity.parent_category ? activity.parent_category : activity.category;
          // eslint-disable-next-line no-loop-func
          var activityCategory = activitiesCategories.find(c => c.id === activityMainCategory.id);
          if (!activityCategory) {
            let newCategory = {
              ...activityMainCategory,
              parent: null,
              subcategories: [],
    
            }
            activitiesCategories.push(newCategory);
          } else {
            if (activity.parent_category) {
              var activitySubcategory = activityCategory.subcategories.find(sc => sc.id === activity.category.id);
              if (!activitySubcategory) {
                activityCategory.subcategories.push(activity.category);
                activityCategory.subcategories.sort(sortOn("position"));
              }
            }
          }
        }
        activitiesCategories.sort(sortOn("position"));

        for (const category of activitiesCategories) {
          for (const subcategory of category.subcategories) {
            subcategory.parent = category
          }
        }

        let servicesCategories = [];
        for (const service of services) {
          var serviceMainCategory = service.parent_category ? service.parent_category : service.category;
          // eslint-disable-next-line no-loop-func
          var serviceCategory = servicesCategories.find(c => c.id === serviceMainCategory.id);
          if (!serviceCategory) {
            let newCategory = {
              ...serviceMainCategory,
              parent: null,
              subcategories: [],
    
            }
            servicesCategories.push(newCategory);
          } else {
            if (service.parent_category) {
              var serviceSubcategory = serviceCategory.subcategories.find(sc => sc.id === service.category.id);
              if (!serviceSubcategory) {
                service.category.parent = service.parent_category;
                serviceCategory.subcategories.push(service.category);
                serviceCategory.subcategories.sort(sortOn("position"));
              }
            }
          }
        }
        servicesCategories.sort(sortOn("position"));

        for (const category of servicesCategories) {
          for (const subcategory of category.subcategories) {
            subcategory.parent = category
          }
        }

        const geozoneQuery = "/geo-zones";
        axios
          .get(geozoneQuery)
          .then((response) => {
            let geozones = response.data.data ? response.data.data : [];
            geozones = geozones.map((geozone) => {
              return {
                ...geozone,
                enabled: true,
                activities: allActivities.filter((activity) => {
                  return activity.geo_zone_id === geozone.id;
                }),
              };
            });
            dispatch(
              setInitialAppData(
                geozones,
                activitiesCount,
                servicesCount,
                activities,
                services,
                activitiesCategories,
                servicesCategories
              )
            );
          })
          .catch((error) => {
            dispatch(fetchGeozonesFailed());
          });
      })
      .catch((error) => {
        dispatch(fetchActivitiesFailed());
      });
  };
};

export const setInitialAppData = (
  geozones,
  activitiesCount,
  servicesCount,
  activities,
  services,
  activitiesCategories,
  servicesCategories
) => {
  return {
    type: actionTypes.INIT_APP_DATA,
    geozones: geozones,
    activitiesCount: activitiesCount,
    servicesCount: servicesCount,
    activities: activities,
    services: services,
    activitiesCategories: activitiesCategories,
    servicesCategories: servicesCategories
  };
};

export const resetApp = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET,
    });
    i18n.changeLanguage("fr").then((t) => {
      dispatch(initApp());
    });
  };
};

export const switchLanguage = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SWITCH_LANGUAGE,
    });
    dispatch(initApp());
  };
};

export const setUserId = (userId) => {
  return {
    type: actionTypes.SET_USER_ID,
    userId: userId,
  };
};

export const setTutorial = (active) => {
  return {
    type: actionTypes.SET_TUTORIAL,
    active: active,
  };
};

export const setGeozones = (geozones) => {
  return {
    type: actionTypes.SET_GEOZONES,
    geozones: geozones,
  };
};

export const updateGeozones = (geozones) => {
  return {
    type: actionTypes.UPDATE_GEOZONES,
    geozones: geozones,
  };
};

export const fetchGeozonesFailed = () => {
  return {
    type: actionTypes.FETCH_GEOZONES_FAILED,
  };
};

export const setActivities = (
  activitiesCount,
  servicesCount,
  activities,
  services
) => {
  return {
    type: actionTypes.SET_ACTIVITIES,
    activitiesCount: activitiesCount,
    servicesCount: servicesCount,
    activities: activities,
    services: services,
  };
};

export const fetchActivitiesFailed = () => {
  return {
    type: actionTypes.FETCH_ACTIVITIES_FAILED,
  };
};

export const setCurrentActivity = (activity) => {
  return {
    type: actionTypes.SET_CURRENT_ACTIVITY,
    currentActivity: activity,
  };
};

export const setRelatedActivity = (activity) => {
  return {
    type: actionTypes.SET_RELATED_ACTIVITY,
    relatedActivity: activity,
  };
};

export const fetchQuestions = () => {
  return (dispatch) => {
    let parameters = "language=" + i18n.language;
    axios
      .get("/questions?" + parameters)
      .then((response) => {
        const questions = response.data.data ? response.data.data : [];
        const questions_initial_count = response.data.count;
        dispatch(setQuestions(questions, questions_initial_count));
      })
      .catch((error) => {
        dispatch(fetchQuestionsFailed());
      });
  };
};

export const setQuestions = (questions, questions_initial_count) => {
  return {
    type: actionTypes.SET_QUESTIONS,
    questions: questions,
    questions_initial_count: questions_initial_count,
  };
};

export const fetchQuestionsFailed = () => {
  return {
    type: actionTypes.FETCH_QUESTIONS_FAILED,
  };
};

export const removeQuestion = (question) => {
  return {
    type: actionTypes.REMOVE_QUESTION,
    question: question,
  };
};

export const addActivityToSelection = (activity) => {
  return {
    type: actionTypes.ADD_ACTIVITY_TO_SELECTION,
    activity: activity,
  };
};

export const removeActivityFromSelection = (activity) => {
  return {
    type: actionTypes.REMOVE_ACTIVITY_FROM_SELECTION,
    activity: activity,
  };
};

export const toggleSelection = (opened) => {
  return {
    type: actionTypes.TOGGLE_SELECTION,
    selectionOpened: opened,
  };
};

export const updateSearch = (params) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_SEARCH,
      params: params,
    });
    dispatch(fetchData(params));
  };
};

export const resetSearch = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_SEARCH,
    });
    dispatch(fetchData({}));
  };
};

export const fetchData = (params) => {
  let query = "language=" + i18n.language + "&";
  if (params.categories) {
    params.categories = [params.categories.slug];
  }
  for (const [key, value] of Object.entries(params)) {
    if (value !== null) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          query += key + "=" + value + "&";
        } else {
          query += key + "=" + 0 + "&";
        }
      } else {
        query += key + "=" + value + "&";
      }
    }
  }
  return (dispatch) => {
    const activitiesQuery = "/activities?" + query;
    axios
      .get(activitiesQuery)
      .then((response) => {
        const activitiesCount = response.data.count_activities
          ? response.data.count_activities
          : 0;
        const servicesCount = response.data.count_services
          ? response.data.count_services
          : 0;
        const activities = response.data.activities
          ? response.data.activities
          : [];
        const services = response.data.services
          ? response.data.services
          : [];
        const allActivities = activities.concat(services);

        let geozonesQuery = "/geo-zones";
        if (params.date_min) {
          geozonesQuery = "/geo-zones?date=" + params.date_min;
        }
        axios
          .get(geozonesQuery)
          .then((response) => {
            let geozones = response.data.data ? response.data.data : [];
            geozones = geozones.map((geozone) => {
              return {
                id: geozone.id,
                forecasts: geozone.forecasts,
                activities: allActivities.filter((activity) => {
                  return activity.geo_zone_id === geozone.id;
                }),
              };
            });
            dispatch(updateGeozones(geozones));
            dispatch(
              setActivities(
                activitiesCount,
                servicesCount,
                activities,
                services
              )
            );
          })
          .catch((error) => {
            dispatch(fetchGeozonesFailed());
          });
      })
      .catch((error) => {
        dispatch(fetchActivitiesFailed());
      });
  };
};

export const setUserLocation = (lngLat) => {
  return {
    type: actionTypes.SET_USER_LOCATION,
    userLocation: lngLat,
  };
};

export const sortActivities = (sortBy) => {
  return {
    type: actionTypes.SORT_ACTIVITIES,
    sortBy: sortBy,
  };
};
