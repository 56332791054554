import React from "react";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-i18next";
import * as actions from "../../../store/actions";
import classes from "./Welcome.module.css";
import iconFavorites from "../../../assets/images/icon_favorites.svg";
import iconFavoritesSelected from "../../../assets/images/icon_favorites_selected.svg";

const Welcome = (props) => {
  const { t } = useTranslation();

  return (
    <div className={classes.welcome}>
      <div>
        <h2>{t("welcome.title")}</h2>
        <p>{t("welcome.description")}</p>
      </div>
      <h3>{t("welcome.step_title")}</h3>
      <ol>
        <li>{t("welcome.step.1")}</li>
        <li>
          <span>{t("welcome.step.2.p1")}</span>
          <br />
          <br />
          <span>{t("welcome.step.2.p2")}</span>
          <br />
          <span>{t("welcome.step.2.p3")}</span>
          <img src={iconFavorites} alt="" />
          <span>{t("welcome.step.2.p4")}</span>
        </li>
        <li>
          <span>{t("welcome.step.3.p1")}</span>
          <br />
          <span>{t("welcome.step.3.p2")}</span>
          <img src={iconFavoritesSelected} alt="" />
          <span>{t("welcome.step.3.p3")}</span>
          <br />
          <span>{t("welcome.step.3.p4")}</span>
        </li>
      </ol>
      <button onClick={() => props.setTutorial(false)}>
        {t("understand")}
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tutorial: state.tutorial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTutorial: (active) => dispatch(actions.setTutorial(active)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Welcome));
