import React, { Component } from 'react';
import i18n from '../../i18n';

import classes from './GeozoneMarker.module.css';

class GeozoneMarker extends Component {

  state = {
    forecastsExpanded: false
  }

  toggleForecastsHandler = (event) => {

    if (this.props.geozone.enabled) {
      event.stopPropagation();
      this.setState({
        forecastsExpanded: !this.state.forecastsExpanded
      }, () => {
        if (this.state.forecastsExpanded) {
          this.props.onWeatherForecastClicked();
        }
      });
    }
  }

  render() {

    const markerClasses = [classes.GeozoneMarker, this.props.geozone.enabled ? classes.enabled : classes.disabled ];

    let borderRadius = '';

    switch (this.props.geozone.anchor) {
      case 'bottom-left':
        borderRadius = '15px 15px 15px 0';
        break;
      case 'bottom-right':
        borderRadius = '15px 15px 0 15px';
        break;
      case 'top-right':
        borderRadius = '15px 0 15px 15px';
        break;
      case 'top-left':
        borderRadius = '0 15px 15px 15px';
        break;
      default:
        borderRadius = '15px 15px 15px 15px';
        break;
    }
    
    let forecasts = null;
    let forecastsWidth = 40;
    const forecastsClasses = [classes.Forecasts];
    const forecastIconClasses = [classes.ToggleIcon];

    if (this.props.geozone.forecasts) {

      forecasts = <img src={this.props.geozone.forecasts[0].forecast_icon.url} alt={this.props.geozone.forecasts[0].forecast} />;
      if (this.state.forecastsExpanded) {
        forecastsWidth = 29 * this.props.geozone.forecasts.length;
        forecasts = this.props.geozone.forecasts.map( forecast => {
          const date = new Date(forecast.date);
          const dateFormated = new Intl.DateTimeFormat(i18n.language, { day: '2-digit', month: '2-digit' }).format(date);
          return (
            <div key={this.props.geozone.id+"_"+forecast.date} className={classes.Forecast}>
              <img src={forecast.forecast_icon.url} alt={forecast.forecast}/>
              <div className={classes.Date}>
                <span>{dateFormated}</span>
              </div>
            </div>
          )
        });
        forecastsClasses.push(classes.Expanded);
        forecastIconClasses.push(classes.Expanded);
      }

      if (this.props.geozone.forecasts.length > 1) {

        forecasts = 
          <div className={classes.ForecastsContainer} onClick={(event) => this.toggleForecastsHandler(event)}>
            <div className={forecastsClasses.join(' ')} style={{width: forecastsWidth + 'px'}} >
              {forecasts}
            </div>
            <div className={forecastIconClasses.join(' ')}>+</div>
          </div>

      } else {

        forecasts = 
          <div className={classes.ForecastsContainer}>
            <div className={forecastsClasses.join(' ')} style={{width: forecastsWidth + 'px'}} >
              {forecasts}
            </div>
          </div>
      }
    }
    
    return (
      <div className={markerClasses.join(' ')}
        style={{
          'border': '2px solid ' + this.props.geozone.color,
          'borderRadius': borderRadius,
        }}
        onClick={this.props.clicked}>
        <div className={classes.Check} style={{'backgroundColor' : this.props.geozone.color}} />
        <div className={classes.Content} >
          <div className={classes.Logo} >
            <img src={this.props.geozone.image.url} alt={this.props.geozone.name} height='100%' />
          </div>
          {forecasts}
        </div>
      </div>
    );
  }
}

export default GeozoneMarker;
