import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as actions from '../../store/actions';

import classes from './Questions.module.css';
import iconNewQuestion from '../../assets/images/icon_new_question.svg';
import Question from './Question/Question';
// import { GAEvent} from '../../components/Analytics/Analytics';
import { GAEvent} from '../../components/Analytics/Firebase';

class Questions extends Component {

  state = {
    currentQuestion: null
  }

  componentDidMount() {

    this.props.fetchQuestions();
  }

  componentDidUpdate(prevProps) {

    if (this.props.questions_initial_count > 0) {
      if (!this.state.currentQuestion || prevProps.questions.length === 0) {
        this.loadRandomQuestion();
      } else if (this.props.questions.length === 0) {
        this.props.fetchQuestions();
      }
    }
  }

  loadRandomQuestion = () => {

    // GAEvent('questions', 'new_question', 'New question');
    GAEvent('new_question');
    const question = this.props.questions[Math.floor(Math.random() * this.props.questions.length)];    
    this.setState({
      currentQuestion: question,
    }, () => {
      this.props.removeQuestion(question);
    });
  }

  render() {

    const { t } = this.props;

    let question = null;
    if (this.props.transitionState === 'entered' && this.state.currentQuestion) {
      question = <Question question={this.state.currentQuestion} />;
    }

    return (
      <div className={[classes.Questions, this.props.transitionState].join(' ')} >
        <section className={classes.Header}>
          <h3>{t('questions.title')}</h3>
          <button onClick={this.loadRandomQuestion}>
            <img src={iconNewQuestion} alt={t('questions.new')} width='20px'/><br />
            {t('questions.new')}
          </button>
        </section>
        <section>
          {question}
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    questions: state.questions,
    questions_initial_count: state.questions_initial_count
  };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchQuestions: () => dispatch(actions.fetchQuestions()),
    removeQuestion: (question) => dispatch(actions.removeQuestion(question)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Questions));