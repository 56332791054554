import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DragDropContainer } from "react-drag-drop-container";

import classes from "../MenuItem.module.css";

import soloIcon from "../../../assets/images/icon_solo.svg";
import soloIconSelected from "../../../assets/images/icon_solo_selected.svg";
import familleIcon from "../../../assets/images/icon_famille.svg";
import familleIconSelected from "../../../assets/images/icon_famille_selected.svg";

import userLocationIcon from "../../../assets/images/icon_user_location.svg";

import piedIcon from "../../../assets/images/icon_pied.svg";
import piedIconSelected from "../../../assets/images/icon_pied_selected.svg";
import veloIcon from "../../../assets/images/icon_velo.svg";
import veloIconSelected from "../../../assets/images/icon_velo_selected.svg";
import voitureIcon from "../../../assets/images/icon_voiture.svg";
import voitureIconSelected from "../../../assets/images/icon_voiture_selected.svg";
import { SERVICES_TAB } from "../../../containers/Home/Home";

const Profil = (props) => {

  const { t } = useTranslation();

  const [publicType, setPublicType] = useState(props.publicTypeSelected);
  const [userLocomotionType, setUserLocomotionType] = useState(
    props.userLocomotionTypeSelected
  );
  const [travelTimeMax, setTravelTimeMax] = useState(
    props.travelTimeMaxSelected ? props.travelTimeMaxSelected : 0
  );

  const timeSliderRef = useRef(null);

  useEffect(() => {
    updateTimeSlider(timeSliderRef.current);
  }, []);

  const publicTypeChangeHandler = (event) => {
    if (event.target.value !== publicType) {
      setPublicType(event.target.value);
    } else {
      event.target.checked = false;
      setPublicType(null);
    }
  };

  const userLocomotionTypeChangeHandler = (event) => {
    if (event.target.value !== userLocomotionType) {
      setUserLocomotionType(event.target.value);
    } else {
      event.target.checked = false;
      setUserLocomotionType(null);
    }
  };

  const timeSliderHandler = (event) => {
    const slider = event.target;
    updateTimeSlider(slider);
  };

  const updateTimeSlider = (slider) => {
    const val = Number(slider.value);
    const min = Number(slider.min);
    const max = Number(slider.max);
    const newVal = Number(((val - min) * 100) / (max - min));
    const output = document.getElementById("time-range-output");
    if (val === max || val === min) {
      output.innerHTML = "";
    } else if (val > min && val < 60) {
      output.innerHTML = val + " min";
    } else if (val < max) {
      const hours = Math.trunc(val / 60);
      const minutes = Math.trunc(val % 60);
      output.innerHTML = minutes === 0 ? hours + "h" : hours + "h" + minutes;
    }
    output.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;

    if (val !== travelTimeMax) {
      setTravelTimeMax(val);
    }
  };

  return (
    <div className={classes.MenuItem}>
      { props.type !== SERVICES_TAB &&
        <section>
          <span>{t("profil.public.title.1")}</span>
          <br />
          <span className={classes.Smaller}>{t("profil.public.title.2")}</span>
          <div className={classes.ButtonsList}>
            <ul>
              <li>
                <input
                  id="button-public-type-1"
                  type="radio"
                  name="public-type"
                  className={classes.VisuallyHidden}
                  value="solo"
                  onClick={(event) => publicTypeChangeHandler(event)}
                  defaultChecked={"solo" === publicType}
                />
                <label htmlFor="button-public-type-1">
                  <img src={soloIcon} alt={t("activity.public_types.solo")} />
                  <img
                    src={soloIconSelected}
                    alt={t("activity.public_types.solo")}
                  />
                </label>
                <span>{t("activity.public_types.solo")}</span>
              </li>
              <li>
                <input
                  id="button-public-type-3"
                  type="radio"
                  name="public-type"
                  className={classes.VisuallyHidden}
                  value="famille"
                  onClick={(event) => publicTypeChangeHandler(event)}
                  defaultChecked={"famille" === publicType}
                />
                <label htmlFor="button-public-type-3">
                  <img
                    src={familleIcon}
                    alt={t("activity.public_types.family")}
                  />
                  <img
                    src={familleIconSelected}
                    alt={t("activity.public_types.family")}
                  />
                </label>
                <span>{t("activity.public_types.family")}</span>
              </li>
            </ul>
          </div>
        </section>
      }
      <section>
        <div className={classes.Row}>
          <div>
            <span>{t("profil.location.title")}</span>
            <div className={classes.UserLocation}>
              <div
                className={
                  !props.userLocation
                    ? classes.UserLocationIcon
                    : [
                        classes.UserLocationIcon,
                        classes.UserLocationActive,
                      ].join(" ")
                }
              >
                <DragDropContainer targetKey="userLocation">
                  <img
                    src={userLocationIcon}
                    alt={t("profil.location.title")}
                  />
                </DragDropContainer>
              </div>
              <div>
                {!props.userLocation ? (
                  <span>
                    {t("profil.location.text.1")}
                    <br />
                    {t("profil.location.text.2")}
                  </span>
                ) : (
                  <button
                    className={classes.UserLocationDelete}
                    onClick={props.deleteUserLocation}
                  >
                    {t("delete")}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div>
            <span>{t("profil.locomotion.title")}</span>
            <div className={classes.ButtonsList}>
              <ul>
                <li>
                  <input
                    id="button-locomotion-type-1"
                    type="radio"
                    name="locomotion-type"
                    className={classes.VisuallyHidden}
                    value="pied"
                    onClick={(event) => userLocomotionTypeChangeHandler(event)}
                    defaultChecked={"pied" === userLocomotionType}
                    disabled={!props.userLocation}
                  />
                  <label htmlFor="button-locomotion-type-1">
                    <img
                      src={piedIcon}
                      alt={t("activity.locomotion_types.foot")}
                    />
                    <img
                      src={piedIconSelected}
                      alt={t("activity.locomotion_types.foot")}
                    />
                  </label>
                  <span htmlFor="button-locomotion-type-1">
                    {t("activity.locomotion_types.foot")}
                  </span>
                </li>
                <li>
                  <input
                    id="button-locomotion-type-2"
                    type="radio"
                    name="locomotion-type"
                    className={classes.VisuallyHidden}
                    value="velo"
                    onClick={(event) => userLocomotionTypeChangeHandler(event)}
                    defaultChecked={"velo" === userLocomotionType}
                    disabled={!props.userLocation}
                  />
                  <label htmlFor="button-locomotion-type-2">
                    <img
                      src={veloIcon}
                      alt={t("activity.locomotion_types.bike")}
                    />
                    <img
                      src={veloIconSelected}
                      alt={t("activity.locomotion_types.bike")}
                    />
                  </label>
                  <span>{t("activity.locomotion_types.bike")}</span>
                </li>
                <li>
                  <input
                    id="button-locomotion-type-3"
                    type="radio"
                    name="locomotion-type"
                    className={classes.VisuallyHidden}
                    value="voiture"
                    onClick={(event) => userLocomotionTypeChangeHandler(event)}
                    defaultChecked={"voiture" === userLocomotionType}
                    disabled={!props.userLocation}
                  />
                  <label htmlFor="button-locomotion-type-3">
                    <img
                      src={voitureIcon}
                      alt={t("activity.locomotion_types.car")}
                    />
                    <img
                      src={voitureIconSelected}
                      alt={t("activity.locomotion_types.car")}
                    />
                  </label>
                  <span>{t("activity.locomotion_types.car")}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div>
          <span>{t("profil.location.perimetre")}</span>
          <div className={classes.SlideContainer}>
            <input
              id="time-range"
              ref={timeSliderRef}
              className={classes.Slider}
              type="range"
              onChange={(event) => timeSliderHandler(event)}
              defaultValue={travelTimeMax}
              min="0"
              max="120"
              step="15"
              disabled={!props.userLocation}
            />
            <output
              className={classes.SliderValue}
              id="time-range-output"
            ></output>
            <span className={classes.SliderMinValue} htmlFor="time-range">
              0 min
            </span>
            <span className={classes.SliderMaxValue} htmlFor="time-range">
              2H
            </span>
          </div>
        </div>
      </section>
      <div className={classes.Actions}>
        <button
          className={classes.Close}
          onClick={() =>
            props.submit({
              publicType: publicType,
              userLocomotionType: userLocomotionType,
              travelTimeMax: travelTimeMax,
              //level: level,
            })
          }
        >
          {t("OK")}
        </button>
        <button
          className={classes.Next}
          onClick={() =>
            props.submit(
              {
                publicType: publicType,
                userLocomotionType: userLocomotionType,
                travelTimeMax: travelTimeMax,
                //level: level,
              },
              true
            )
          }
        >
          {t("profil.next.1")}
          <span className={classes.Smaller}>{t("profil.next.2")}</span>
        </button>
      </div>
    </div>
  );
};

export default Profil;
